// 'hello world' -> 'hello-world'
// 'apple, orange, banana' -> 'apple-orange-banana'
// 'string with spaces, and commas' -> 'string-with-spaces-and-commas'

export const transformToDashes = (input, separators = [',', ' ']) => {
  if (!input || typeof input !== 'string') {
    return ''
  }

  let result = input.trim().toLowerCase()

  separators.forEach(separator => {
    result = result
      .split(separator)
      .filter(part => part.trim())
      .join('-')
  })

  result = result.replace(/-+/g, '-')

  return result
}
