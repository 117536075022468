import { render, staticRenderFns } from "./UserInfoBox.vue?vue&type=template&id=fac86448&scoped=true"
import script from "./UserInfoBox.vue?vue&type=script&lang=js"
export * from "./UserInfoBox.vue?vue&type=script&lang=js"
import style0 from "./UserInfoBox.vue?vue&type=style&index=0&id=fac86448&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fac86448",
  null
  
)

export default component.exports