import { TERM_TYPE } from '@/constants'
import Vue from 'vue'

export const getDeliveryParams = async ({
  selectedForDeliveryCity,
  productsWarehouseCity,
  quantity,
  weightCoefficient,
}) => {
  const { price_kg, days, min_weight_kg } = await Vue.$http.get(
    `/order/delivery/${productsWarehouseCity}/${selectedForDeliveryCity}/${TERM_TYPE.DIRECT}/delivery`,
  )

  return {
    days,
    priceKg: price_kg,
    deliveryPrice: quantity * price_kg * weightCoefficient,
    minimalWeight: min_weight_kg,
  }
}
