import { isProdClient, YANDEX_METRICA_ID } from '@/constants'

const CURRENCY_CODE = 'RUB'

export const ECOMMERCE_ACTION_TYPE = {
  impressions: 'impressions',
  click: 'click',
  add: 'add',
  detail: 'detail',
  remove: 'remove',
  purchase: 'purchase',
}

const makeProductFieldObject = (sellerProduct, list = '') => {
  return {
    id: `${sellerProduct.id}`,
    name: `${sellerProduct.product.name_ru || sellerProduct.product.name} ${sellerProduct.sort.name}`,
    brand: `${sellerProduct.brand?.name || 'Не указан'}`,
    price: sellerProduct.price,
    category: `${sellerProduct.group.name_ru || sellerProduct.group.name}`,
    discount: sellerProduct.discount,
    position: sellerProduct.position || 1,
    quantity: sellerProduct.quantity || 0,
    list,
  }
}

const pushToDataLayer = payload => {
  window.dataLayer.push({
    ecommerce: {
      currencyCode: CURRENCY_CODE,
      ...payload,
    },
  })
}

export const sendMetrics = async (goalID, params) => {
  if (!isProdClient) {
    return
  }

  if (typeof window !== 'undefined' && typeof window.ym !== 'undefined') {
    window.ym(YANDEX_METRICA_ID, 'reachGoal', goalID, params)
  }
}

export const pushEcommerce = async ({ actionType, products = [], allProducts = [], list = 'Маркет', actionField }) => {
  if (!isProdClient) {
    return
  }

  if (typeof window === 'undefined' || typeof window.dataLayer === 'undefined') {
    return
  }

  const positionsMap = new Map()

  allProducts.forEach((product, idx) => {
    positionsMap.set(product.id, idx + 1)
  })

  try {
    if (actionType === ECOMMERCE_ACTION_TYPE.impressions) {
      pushToDataLayer({
        impressions: products.map(product =>
          makeProductFieldObject({ ...product, position: positionsMap.get(product.id) }, list),
        ),
      })
    }

    if (actionType === ECOMMERCE_ACTION_TYPE.click) {
      pushToDataLayer({
        click: {
          products: products.map(product =>
            makeProductFieldObject({ ...product, position: positionsMap.get(product.id) }, list),
          ),
        },
      })
      return
    }

    if (actionType === ECOMMERCE_ACTION_TYPE.detail) {
      pushToDataLayer({
        detail: {
          products: products.map(product =>
            makeProductFieldObject({ ...product, position: positionsMap.get(product.id) }, list),
          ),
        },
      })
      return
    }

    if (actionType === ECOMMERCE_ACTION_TYPE.add) {
      pushToDataLayer({
        add: {
          products: products.map(product =>
            makeProductFieldObject({ ...product, position: positionsMap.get(product.id) }, list),
          ),
        },
      })
      return
    }

    if (actionType === ECOMMERCE_ACTION_TYPE.remove) {
      pushToDataLayer({
        remove: {
          products: products.map(product => makeProductFieldObject({ ...product }, list)),
        },
      })
      return
    }

    if (actionType === ECOMMERCE_ACTION_TYPE.purchase) {
      pushToDataLayer({
        purchase: {
          actionField,
          products: products.map(product => makeProductFieldObject({ ...product }, list)),
        },
      })
    }
  } catch (e) {
    console.error(e)
  }
}
