import store from '@/store'

export default [
  // Demands
  {
    path: '/demands',
    component: () => import('../../views/BlankChildrenRoute.js'),
    beforeEnter(to, from, next) {
      store.getters['user/isBuyer'] ? next({ name: 'applications' }) : next()
    },
    children: [
      {
        path: '/demands',
        name: 'demands',
        component: () => import('../../views/Demands.vue'),
        meta: {
          requiresAuth: true,
          sidebarComponents: [
            // 'GroupsFilter',
            'ModalTriggerList',
            'StatusesFilter',
          ],
        },
      },
      {
        path: ':itemId',
        component: () => import('../../views/Demand.vue'),
        name: 'demand',
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  // Products
  {
    path: '/products',
    name: 'products',
    component: () => import('../../views/MyProducts.vue'),
    beforeEnter(to, from, next) {
      store.getters['user/isBuyer'] ? next({ name: 'market' }) : next()
    },
    meta: {
      requiresAuth: true,
      sidebarComponents: ['GroupsFilter', 'ModalTriggerList', 'CategoriesFilter'],
      showClearFiltersButton: true,
    },
    props: true,
    children: [
      {
        path: ':productId',
        name: 'myProduct',
        component: () => import('../../views/MyProducts.vue'),
      },
    ],
  },
  // Clients
  {
    path: '/clients',
    component: () => import('../../views/BlankChildrenRoute.js'),
    beforeEnter(to, from, next) {
      store.getters['user/isBuyer'] ? next({ name: 'providers' }) : next()
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/clients',
        component: () => import('../../views/Clients.vue'),
        name: 'clients',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':userId',
        component: () => import('../../views/Client.vue'),
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '/clients/:userId',
            component: () => import('../../views/client/Info.vue'),
            name: 'client',
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'demands',
            component: () => import('../../views/client/Demands.vue'),
            name: 'clientDemands',
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'chat',
            component: () => import('../../views/client/Chat.vue'),
            name: 'clientChat',
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  // Pending orders
  {
    path: '/pending-orders',
    component: () => import('../../views/BlankChildrenRoute.js'),
    beforeEnter(to, from, next) {
      store.getters['user/isSeller'] ? next() : next({ name: 'home' })
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/pending-orders',
        component: () => import('../../views/PendingOrders.vue'),
        name: 'pendingOrders',
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
]
