<template>
  <div class="user-info-box">
    <router-link
      v-if="Object.keys(userRoute).length"
      :to="userRoute"
      class="user-info-box__image-area"
      :class="smallImage ? 'user-info-box__image-area--small' : ''"
      @click.native.prevent="navigateToProfile"
    >
      <img v-if="pic" class="user-info-box__image" :src="imageUrl(pic)" :alt="name" />
      <PlaceHolderImageGenerator v-else class="user-info-box__image" :image-name="name" />
    </router-link>
    <div v-else class="user-info-box__image-area" :class="smallImage ? 'user-info-box__image-area--small' : ''">
      <button class="user-info-box__image-btn" :disabled="!pic || !showLargePhotoOnClick" @click="showPicPreview">
        <img v-if="pic" class="user-info-box__image" :src="imageUrl(pic)" :alt="name" />
        <PlaceHolderImageGenerator v-else class="user-info-box__image" :image-name="name" />
      </button>
    </div>

    <div class="user-info-box__data">
      <div v-if="Object.keys(userRoute).length" class="user-info-box__name">
        <OnlineStatus :is-online="isOnline" />
        <router-link :to="userRoute" class="user-info-box__name" @click.native.prevent="navigateToProfile">
          <span>{{ name }}</span>
        </router-link>
      </div>
      <div v-else class="user-info-box__name">
        <OnlineStatus :is-online="isOnline" />
        <span>{{ name }}</span>
      </div>
      <Rating v-if="showRating" class="user-info-box_rating" :rate="rating" />
      <a v-if="showReviews" :href="reviewsLink" class="user-info-box__reviews-link">{{
        $t('{0} reviews', [reviewsCounter])
      }}</a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OnlineStatus from '@/components/common/OnlineStatus.vue'
import Rating from '@/components/common/Rating.vue'
import PlaceHolderImageGenerator from '@/components/common/PlaceHolderImageGenerator.vue'
import { eventBus } from '@/helpers'
import { resolveAssetUrl } from '@/helpers/url'

export default {
  components: {
    OnlineStatus,
    Rating,
    PlaceHolderImageGenerator,
  },
  props: {
    userRoute: {
      type: Object,
      default() {
        return {}
      },
    },
    link: String,
    name: String,
    pic: String,
    picMedium: String,
    picLarge: String,
    rating: Number,
    isOnline: Boolean,
    reviewsLink: String,
    reviewsCounter: Number,
    showLargePhotoOnClick: {
      type: Boolean,
      required: false,
      default: true,
    },
    showReviews: {
      type: Boolean,
      default: true,
    },
    showRating: {
      type: Boolean,
      default: true,
    },
    smallImage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('user', ['currentUser']),
  },
  methods: {
    navigateToProfile() {
      this.$emit('link-click')

      if (+this.userRoute.params.userId === this.currentUser.profile.id) {
        this.$router.push({ name: 'profileIndex' })
      } else {
        this.$router.push(this.userRoute)
      }
    },
    imageUrl(url) {
      return resolveAssetUrl(url)
    },
    showPicPreview() {
      if (this.showLargePhotoOnClick) {
        eventBus.$emit('modal', {
          component: 'ImagePreviewModal',
          data: {
            src: this.imageUrl(this.picMedium),
            alt: this.name,
            original: this.imageUrl(this.picLarge),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.user-info-box {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  // Elements
  .user-info-box__image-area {
    display: block;
    width: 34px;
    height: 34px;
    min-width: 34px;
    margin-right: 10px;
    border-radius: 50%;
    box-shadow: 0 0 0 1px $LGray4;

    @include media-breakpoint-up(lg) {
      width: 57px;
      height: 57px;
    }

    &--small {
      @include media-breakpoint-up(lg) {
        width: 34px;
        height: 34px;
      }
    }
  }
  .user-info-box__image {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .user-info-box__image-btn {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    padding: 0;

    &:not(:disabled) {
      cursor: pointer;
    }
  }
  .user-info-box__data {
    display: grid;
    grid-row-gap: 3px;
  }
  .user-info-box__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    &[href] {
      text-decoration: underline;
    }

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }
  .user-info-box__rating {
    align-self: center;
  }
  .user-info-box__reviews-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    color: #000;
  }
}
</style>
