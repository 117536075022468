<template>
  <ul class="main-nav">
    <li
      v-for="route in navigation"
      :key="route.id"
      :class="{
        'main-nav__item': true,
        'main-nav__item--external': !route.name,
      }"
    >
      <button v-if="route.modal" class="main-nav__link" type="button" @click="$emit('show-modal', route.modal)">
        {{ $t(route.title) }}
      </button>

      <a v-else-if="route.link" :href="route.link" target="_blank" class="main-nav__link">
        {{ $t(route.title) }}
      </a>

      <router-link
        v-else
        class="main-nav__link"
        :class="{ 'have-messages': route.haveMessages }"
        :to="{ name: route.name }"
        :active-class="`main-nav__link--current`"
      >
        {{ $t(route.title) }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MainNav',
  props: {
    navigation: Array,
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.main-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  // Elements
  .main-nav__item {
    display: inline-flex;

    & + .main-nav__item {
      margin-left: 16px;

      @include media-breakpoint-up(md) {
        margin-left: 32px;
      }
    }

    &--external {
      & + .main-nav__item {
        @include media-breakpoint-up(md) {
          margin-left: 24px;
        }
      }

      & .main-nav__link {
        transition: color 100ms ease;

        &:hover {
          color: $GreenM;
        }
      }
    }
  }

  .main-nav__link {
    display: inline-flex;
    align-items: center;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: none;
    border-right: none;
    min-height: 50px;
    font-weight: 500;
    font-size: 16px;
    color: $Black;
    white-space: nowrap;
    text-decoration: none;
    background-color: transparent;

    @include media-breakpoint-up(md) {
      min-height: $size-header-height;
      padding: 0 5px;
    }

    // States
    &--current {
      border-bottom-color: $GreenM;
    }
  }
}
</style>
