<template>
  <label
    class="checkbox-item"
    :class="{
      'checkbox-item--s': size === 's',
      'checkbox-item--m': size === 'm',
      'checkbox-item--l': size === 'l',
      'checkbox-item--disabled': disabled,
      'checkbox-item--darkmode': darkmode,
    }"
    :for="id"
  >
    <input
      :id="id"
      :name="name"
      :checked="checkboxValue"
      :disabled="disabled"
      type="checkbox"
      class="checkbox-item__field"
      @input="toggle"
    />

    <span class="checkmark"></span>

    <slot name="label" :label="label">
      <span v-if="label" class="checkbox-item__label">{{ label }}</span>
    </slot>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    value: Boolean,
    id: String,
    name: String,
    label: String,
    darkmode: Boolean,
    size: {
      type: String,
      required: false,
      default: 's',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      checkboxValue: this.value,
    }
  },
  watch: {
    value() {
      this.checkboxValue = this.value
    },
  },
  methods: {
    toggle() {
      this.checkboxValue = !this.checkboxValue
      this.$emit('input', this.checkboxValue)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/config';

.checkbox-item {
  display: inline-flex;
  align-items: center;
  position: relative;
  line-height: 1.2;

  &:not(.checkbox-item--disabled):hover .checkmark {
    border-color: $GreenM;
  }

  .checkmark {
    position: relative;
    background: $White;
    border: 1px solid $Gray;
    border-radius: 4px;
    transition: $transition;
    cursor: pointer;
    flex-shrink: 0;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      background: url('~@/assets/images/icons/check-white.svg');
      background-repeat: no-repeat;
      background-size: 75%;
      background-position: center;
      border-radius: inherit;
      opacity: 0;
      transition: $transition ease all 0s;
    }
  }

  &--darkmode {
    .checkmark {
      background: transparent;
      border-color: $DGray;
    }

    span.checkbox-item__label {
      color: $White;
    }
  }

  &--disabled .checkmark {
    background-color: $LGray;
  }

  &--s {
    .checkmark {
      width: 14px;
      height: 14px;
    }

    .checkbox-item__label {
      font-size: 14px;
    }
  }

  &--m {
    .checkmark {
      width: 18px;
      height: 18px;
    }
  }

  &--l {
    .checkmark {
      width: 20px;
      height: 20px;
    }
  }

  .checkbox-item__field {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
  }

  .checkbox-item__field:not(:disabled) ~ .checkmark:hover {
    border-color: $GreenM;
  }

  .checkbox-item__field:checked ~ .checkmark {
    background-color: $GreenM;
    border-color: $GreenM;
  }

  .checkbox-item__field:checked ~ .checkmark::after {
    opacity: 1;
  }

  .checkbox-item__field:disabled + .checkmark {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }

  .checkbox-item__field:disabled ~ .checkbox-item__label {
    cursor: default;
  }

  .checkbox-item__label {
    margin-left: 8px;
    cursor: pointer;
    font-weight: 500;
    color: $DGray2;
    white-space: initial;
  }
}
</style>
