<template>
  <transition v-if="show" name="fade" mode="out-in">
    <div class="notification">
      <div class="notification__content">
        <a href="#" class="notification__close close-popup" @click.prevent="show = false">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.9497 7.05033C17.3403 7.44086 17.3403 8.07402 16.9497 8.46455L8.46447 16.9498C8.07394 17.3404 7.44078 17.3404 7.05025 16.9498C6.65973 16.5593 6.65973 15.9261 7.05025 15.5356L15.5355 7.05033C15.9261 6.65981 16.5592 6.65981 16.9497 7.05033Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.05025 7.05033C7.44078 6.65981 8.07394 6.65981 8.46447 7.05033L16.9497 15.5356C17.3403 15.9261 17.3403 16.5593 16.9497 16.9498C16.5592 17.3404 15.9261 17.3404 15.5355 16.9498L7.05025 8.46455C6.65973 8.07402 6.65973 7.44086 7.05025 7.05033Z"
              fill="black"
            />
          </svg>
        </a>
        <div class="notification__row">
          <div class="notification__left">
            <div class="notification__image">
              <img
                :src="require(`@/assets/images/icons/${isSuccess ? 'success' : 'error'}.svg`)"
                alt=""
                width="40"
                height="40"
              />
            </div>
          </div>
          <div class="notification__right">
            <h2 class="notification__label">
              {{ title }}
            </h2>
            <div class="notification__text">
              {{ $t(body) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { eventBus } from '@/helpers/eventBus'

export default {
  name: 'Notification',
  data() {
    return {
      show: false,
      isSuccess: '',
      title: '',
      body: '',
    }
  },
  mounted() {
    eventBus.$on('notification', params => {
      this.show = true
      this.isSuccess = params.isSuccess
      this.title = params.title
      this.body = params.body
      this.autoClose()
    })
  },
  methods: {
    autoClose() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.notification {
  position: fixed;
  z-index: 10002;
  right: 35px;
  top: 85px;
  -webkit-box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
  background-color: $White;

  @include media-breakpoint-down(sm) {
    top: 100px;
    right: 20px;
    left: 20px;
  }
  @include media-breakpoint-up(md) {
    right: 35px;
  }

  &__close {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    right: 10px;
    top: 10px;
  }

  &__content {
    position: relative;
    max-width: 472px;
    background-color: #fff;
    border-radius: 3px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 40px;
    padding-left: 20px;

    @include media-breakpoint-down(sm) {
      max-width: none;
    }
  }

  &__image {
    display: flex;
    align-items: center;
  }

  &__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &__left {
    margin-right: 24px;
  }

  &__label {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 2px;
    color: #000;
  }
}

.fade-enter {
  transform: translateY(5%);
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
  transform: translateX(10%);
}

.fade-enter-active,
.fade-leave-active {
  transition: $transition;
  transition-property: opacity, transform;
}
</style>
