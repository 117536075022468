/**
 * @returns { number }
 * Возвращает общий вес продуктов в кг
 */

export const totalProductsWeight = products => {
  // Поле weight означает количество а не вес в прямом смысле
  // если wt_unit.weight === null значит единица веса товара не имеет веса (шт/упаковка)
  // и он вычисляется через умножение количества на вес одной
  return products.reduce((total, product) => {
    total +=
      product.wt_unit.weight !== null
        ? product.weight * product.wt_unit.weight
        : product.weight * product.one_item_weight
    return total
  }, 0)
}
