export const cartProductsByCityOrDistrict = cartItemProducts => {
  const result = new Map()

  cartItemProducts.forEach(product => {
    const cityOrDistrictId = product.warehouse_address.city.id
    const currentProductsInRegion = result.get(cityOrDistrictId) || []

    result.set(cityOrDistrictId, [...currentProductsInRegion, product])
  })

  return result
}
