<template>
  <div class="sidebar-checkboxes">
    <p class="sidebar-checkboxes__lead">
      {{ title }}
    </p>

    <input
      v-if="searchable"
      :value="searchValue"
      type="text"
      class="sidebar-checkboxes__input"
      :placeholder="$t('Search')"
      @input="evt => $emit('search-input', evt.target.value.trim())"
    />

    <ul ref="itemsList" class="sidebar-checkboxes__items">
      <li
        v-for="item in items"
        v-show="displayItems.some(i => i.id === item.id)"
        :key="item.id"
        class="sidebar-checkboxes__item"
      >
        <router-link v-if="asLinks" class="sidebar-checkboxes__link" :to="item.route">
          <slot name="label" :item="item">{{ item.name }}</slot>
        </router-link>

        <Checkbox
          v-else
          size="l"
          :darkmode="true"
          :label="item.name"
          :value="itemsSelected.some(i => i.id === item.id)"
          @input="$emit('select', item)"
        >
          <template #label="{ label }">
            <div class="sidebar-checkboxes__item-label">
              <slot name="label" :item="item">{{ label }}</slot>
            </div>
          </template>
        </Checkbox>
      </li>
    </ul>

    <button
      v-if="items.length > defaultMaxItems && !searchValue.length"
      class="button-reset sidebar-checkboxes__toggle-all"
      @click="toggleShowAll"
    >
      {{ isShowAll ? $t('Collapse') : $t('Show all') }}
    </button>
  </div>
</template>

<script>
import Checkbox from '@/components/form/Checkbox.vue'

export default {
  name: 'SidebarCheckboxList',
  components: {
    Checkbox,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemsSelected: {
      type: Array,
      required: true,
    },
    searchValue: {
      type: String,
      required: true,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: true,
    },
    asLinks: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isShowAll: false,
  }),
  computed: {
    defaultMaxItems() {
      return 8
    },
    displayItems() {
      return this.items
        .filter(i => i.name.toLowerCase().includes(this.searchValue.toLowerCase()))
        .slice(0, this.isShowAll || this.searchValue.length ? Infinity : this.defaultMaxItems)
    },
  },
  methods: {
    toggleShowAll() {
      this.isShowAll = !this.isShowAll
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common-imports.scss';
@import '@/assets/scss/silence/scroll-bar-decorative';

.sidebar-checkboxes {
  display: grid;
  gap: 8px;
  padding: 0 2px;

  &__lead {
    font-weight: 500;
  }

  &__items {
    display: grid;
    gap: 8px;
    min-height: 48px;
    // max-height: 250px;
    overflow-y: auto;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 8px;

    @extend %scroll-bar-decorative-small;

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #737373;
    }
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__input {
    margin: 0;
    padding: 0;
    border: 1px solid $DGray;
    background: none;
    font-family: inherit;
    appearance: none;
    border-radius: 8px;
    width: 100%;
    padding: 4px 8px;
    color: $White;
    outline: none;

    &:focus {
      border-color: $Gray3;
    }
  }

  &__checkbox {
    width: 20px;
    height: 20px;
    border-radius: 8px;
    border: 1px solid $DGray;
    background-color: transparent;
  }

  &__item-label {
    display: flex;
    align-items: center;
    padding-left: 8px;
    cursor: pointer;
    font-size: 14px;
  }

  &__link {
    font-size: 14px;
    color: inherit;
    cursor: pointer;
  }

  &__toggle-all {
    font-size: 14px;
    text-align: left;
  }
}
</style>
