import store from '@/store'

export const filterAvailableForPickupWarehouses = (isFromDistributor, products) => {
  const availableForPickupWarehouses = new Map()

  // Товар "в наличии"
  if (isFromDistributor) {
    // Все фулфилмент склады системных продавцов доступны для самовывоза
    store.getters['profile/systemSellers'].forEach(sysSeller => {
      const fulfilmentWh = sysSeller.term_condition.warehouse_address

      if (fulfilmentWh) {
        availableForPickupWarehouses.set(fulfilmentWh.id, fulfilmentWh)
      }
    })

    // Также для самовывоза доступен склад на который добавлены товары при условии, что он одинаковый.
    const allProductsInTheSameWarehouse = new Set(products.map(p => p.warehouse_address.id)).size === 1

    if (allProductsInTheSameWarehouse) {
      const wh = products[0].warehouse_address

      // Проверяем является ли склад товара системным-условным АО БАЗА (не фулфилмент)
      // и если нет – добавляем его для самовывоза поскольку товар находится на этом складе.
      if (!wh.is_system) {
        availableForPickupWarehouses.set(wh.id, wh)
      }
    }
  } else {
    // Товар "предзаказ"
    // Для самовывоза доступны все склады АО БАЗА которые являются фулфилмент-складами.
    // Берем их из term_condition.warehouse_address системных продавцов АО БАЗА
    store.getters['profile/bazaSystemSellers'].forEach(sysSeller => {
      const fulfilmentWh = sysSeller.term_condition.warehouse_address

      if (fulfilmentWh) {
        availableForPickupWarehouses.set(fulfilmentWh.id, fulfilmentWh)
      }
    })
  }

  return Array.from(availableForPickupWarehouses).map(([_, wh]) => wh)
}
