<template>
  <div class="rating" :class="ratingClasses">
    <i
      v-for="(i, index) in maxRating"
      :key="index"
      class="rating__icon icon-star-rating"
      :class="i > Math.round(rate) ? 'rating__icon--dis' : ''"
    />
  </div>
</template>

<script>
export default {
  props: {
    rate: Number,
    bigSize: {
      type: Boolean,
      default: false,
    },
    alignCenter: {
      type: Boolean,
      default: false,
    },
    coloredBackground: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxRating: 5,
    }
  },
  computed: {
    ratingClasses() {
      return {
        'rating--centered-icons': this.alignCenter,
        'rating--big-size': this.bigSize,
        'rating--on-colored': this.coloredBackground,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.rating {
  display: flex;
  // Elements
  .rating__icon {
    font-size: 10px;
    display: inline-flex;
    color: $Yellow;
    & + .rating__icon {
      @include adaptiv-value('margin-left', 4, 8, 1);
    }

    // States
    &--dis {
      color: $DGray;
    }
  }

  // States
  &--centered-icons {
    justify-content: center;
  }

  &--big-size {
    // Elements
    .rating__icon {
      font-size: 20px;

      & + .rating__icon {
        @include adaptiv-value('margin-left', 14, 8, 1);
      }
    }
  }

  &--on-colored {
    // Elements
    .rating__icon {
      // States
      &--dis {
        color: $White;
      }
    }
  }
}
</style>
