import { SELLER_PRODUCT_STATUS } from '@/constants'
import Vue from 'vue'

export const actions = {
  async listSellerProducts({ state, commit }, { signal, ...params }) {
    try {
      const result = await Vue.$http.get('/profile_product/products/', {
        params,
        showLoadingBar: false,
        signal,
      })

      commit('setSellerProducts', result.results)

      if (state.totalPages !== result.total_pages) {
        commit('setTotalPages', result.total_pages)
        commit('setCurrentPage', 1)
      }
    } catch (error) {
      if (error?.message === 'canceled') {
        return
      }

      throw error
    }
  },
  async loadSellerProduct(context, { id, ...params }) {
    const product = await Vue.$http.get(`/profile_product/products/${id}/`, {
      params,
    })

    return product
  },
  async createSellerProduct(context, params) {
    const formData = new FormData()
    const clone = Object.assign({}, params)

    if (clone.images) {
      clone.images.forEach(image => {
        formData.append('image', image)
      })
    }

    delete clone.images

    for (const key in clone) {
      if (clone[key] !== null) {
        formData.append(key, clone[key])
      }
    }

    await Vue.$http.post('/profile_product/products/', formData)
  },
  async updateSellerProduct({ commit, state }, { id, ...params }) {
    let formData = new FormData()
    const clone = Object.assign({}, params)

    const addedImages = clone.images

    if (addedImages) {
      clone.images.forEach(image => {
        formData.append('image', image)
      })
    }

    delete clone.images

    for (const key in clone) {
      formData.append(key, clone[key])
    }

    let result = await Vue.$http.put(`/profile_product/products/${id}/`, formData)

    // При редактировании картинок нужно перезапросить товар
    if (addedImages?.length || params.delete_image_ids?.length) {
      result = await Vue.$http.get(`/profile_product/products/${id}/`)
    }

    const updated = state.sellerProducts.map(product => {
      if (product.id === result.id) {
        return result
      }

      return product
    })

    commit('setSellerProducts', updated)
  },

  async patchSellerProduct({ commit, state }, { id, ...params }) {
    const result = await Vue.$http.patch(`/profile_product/products/${id}/`, params)

    const updated = [...state.sellerProducts].map(product => {
      if (product.id === id) {
        return { ...result }
      }

      return product
    })

    commit('setSellerProducts', updated)
  },

  async deleteSellerProduct({ commit, state }, { id, ...params }) {
    // Сначала ставим статус - не опубликован
    await Vue.$http
      .patch(`/profile_product/products/${id}/`, {
        ...params,
        status: SELLER_PRODUCT_STATUS.not_publish,
      })
      .catch(e => console.warn(e))

    await Vue.$http.delete(`/profile_product/products/${id}/`, params)

    commit(
      'setSellerProducts',
      state.sellerProducts.filter(p => p.id !== id),
    )
  },
  async activateProduct(context, { id, ...params }) {
    await Vue.$http.put(`/profile_product/products/${id}/activate/`, params)
  },
  async changeCurrentPage({ commit }, page) {
    commit('setCurrentPage', page)
  },
  async listSupplyRequests({ commit }, params) {
    const result = await Vue.$http.get(`/profile_product/application/`, { params })
    commit(
      'setSupplyRequests',
      result.results.filter(item => !!item.product),
    )
    commit('setSupplyRequestsTotalPages', result.total_pages)
  },
  async getSupplyRequest(context, { id }) {
    const result = await Vue.$http.get(`/profile_product/application/${id}/`)
    console.log('GET SUPPLY REQUEST BY ID', result)
    return result
    // commit('setSupplyRequest', result)
  },
  async deleteSupplyRequest(context, { id }) {
    const result = await Vue.$http.delete(`/profile_product/application/${id}/`)
    console.log(result)
  },
  async returnSupplyRequestProduct(context, { id, ...params }) {
    console.log(params)
    const result = await Vue.$http.put(`/profile_product/application/${id}/product_return/`, params)
    console.log(result)
  },
  async updateSupplyRequest(context, { id, ...params }) {
    const result = await Vue.$http.patch(`/profile_product/application/${id}/`, params)
    return result
  },
  async listProductsCities({ commit }, params = {}) {
    const result = await Vue.$http.get('/profile_product/cities', { params })
    commit('setProductsCities', result.results)
  },
  async activateSellerProduct({ state, commit }, { id }) {
    const result = await Vue.$http.patch(`/profile_product/products/${id}/activate/`)

    const updated = [...state.sellerProducts].map(product => {
      if (product.id === id) {
        return { ...result }
      }

      return product
    })

    commit('setSellerProducts', updated)
  },
  async addExpense(_, data) {
    const result = await Vue.$http.post('/profile_product/expenses', data)
    return result
  },
  async removeExpense(_, expenseId) {
    const result = await Vue.$http.delete(`/profile_product/expenses/${expenseId}`)
    return result
  },
  async updateExpense(_, { id, ...data }) {
    const result = await Vue.$http.patch(`/profile_product/expenses/${id}`, data)
    return result
  },
  async createProductPromotionRequest(_, data) {
    const result = await Vue.$http.post(`/profile_product/promoted_products/`, data)
    return result
  },
  async updateProductPromotion(_, { id, ...data }) {
    const result = await Vue.$http.patch(`/profile_product/promoted_products/${id}/`, data)
    return result
  },
}
