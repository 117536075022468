<template>
  <div class="counter">
    <InputField
      v-model="inputValue"
      class="counter__field"
      :color="color"
      :type="inputType"
      :placeholder="placeholder"
      :description="description"
      :label="label"
      :mark="mark"
      :readonly="readonly"
      :error="error"
      @input="val => onInputValue(val)"
    >
      <img
        v-if="!readonly"
        src="@/assets/images/icons/uncheck.svg"
        alt=""
        class="counter__decrement counter__icon"
        @click="decrement()"
      />
      <img
        v-if="!readonly"
        src="@/assets/images/icons/plus.svg"
        alt=""
        class="counter__increment counter__icon"
        @click="increment()"
      />
    </InputField>
  </div>
</template>

<script>
import InputField from '@/components/form/Input.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'Counter',
  components: {
    InputField,
  },
  props: {
    value: Number,
    placeholder: String,
    min: { type: Number, default: 1 },
    max: { type: Number, default: 1000000000000 },
    step: { type: Number, default: 10 },
    /**
     * The style of the counter
     * @values white, gray
     */
    color: { type: String, default: 'white' },
    description: String,
    label: String,
    mark: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value || this.min || 0,
      inputType: 'number',
    }
  },
  watch: {
    value: {
      handler() {
        this.inputValue = this.value
      },
      immediate: true,
    },
  },
  methods: {
    increment() {
      this.onChangeValue(this.inputValue + this.step)
      console.log(this.step)
    },
    decrement() {
      this.onChangeValue(this.inputValue - this.step)
      console.log(this.inputValue)
    },
    onChangeValue(val) {
      val = Number(val)

      if (val > this.max) {
        val = this.max
      } else if (val < this.min) {
        val = this.min
      }

      this.inputValue = val
      this.$emit('input', val)
    },
    onInputValue: debounce(function (...args) {
      this.onChangeValue(args)
    }, 1000),
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.counter {
  display: flex;
  align-items: center;
  position: relative;

  .counter__icon {
    cursor: pointer;
  }

  .counter__field {
    width: 100%;
  }

  .counter__decrement {
    width: 9px;
  }
}
</style>
