<template>
  <div class="input-item">
    <p v-if="mark" class="input-item__mark">
      {{ mark }}
    </p>

    <label v-if="label" :for="id" class="input-item__label">{{ label }}</label>

    <div
      :class="{
        'input input-item__input': true,
        'input--gray': color === 'gray',
        'input--success': success,
        'input--disabled': disabled,
        'input--readonly': readonly,
        'input--error': error,
      }"
    >
      <img v-if="leftIcon" class="input__icon input__icon-left" :src="leftIcon" alt="" />

      <input
        :id="id"
        class="input__field"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        :value="value"
        :readonly="readonly"
        :step="step"
        :max="max"
        :min="min"
        @input="e => $emit('input', e.target.value)"
        @blur="$emit('blur', $event)"
      />

      <div v-if="actionButton" class="input__action-btn">
        <button type="button" class="input__action-btn" @click="$emit('action-click')">
          {{ actionButton }}
        </button>
      </div>

      <span v-if="description" class="input-item__description">
        {{ description }}
      </span>

      <slot />
    </div>

    <span v-if="error && errorMessage" class="input-item__error-message">{{ errorMessage }}</span>
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    id: String,
    value: null,
    placeholder: null,
    description: {
      type: String,
      required: false,
      default: '',
    },
    actionButton: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Style of the input
     * @values white, gray
     */
    color: {
      default: 'white',
      type: String,
    },
    /**
     * Type of the input
     * @values text, number and so on
     */
    type: {
      default: 'text',
      type: String,
    },
    success: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    error: {
      default: false,
      type: Boolean,
    },
    errorMessage: String,
    leftIcon: String,
    label: String,
    mark: String,
    max: {
      type: Number,
      required: false,
      default: null,
    },
    min: {
      type: Number,
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      required: false,
      default: 0.1,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.input {
  &--readonly {
    opacity: 0.6;
  }

  min-height: 41px;
  border-radius: 6px;

  &__action-btn {
    > button {
      font-weight: 500;
      background: transparent;
      color: $GreenM;
      border: none;
      cursor: pointer;
      padding: 0 4px;
      margin: 0;

      &:hover {
        opacity: 0.75;
      }
    }
  }
}

.input-item {
  &__error-message {
    color: $Red;
    font-size: 14px;
    padding: 0 2px;
  }

  .input-item__label {
    margin-bottom: 4px;
    padding: 0 2px;
  }

  .input-item__mark {
    font-size: 12px;
    color: $Gray;
    margin-bottom: 10px;
  }

  .input__field {
    font-size: 16px;
  }

  .input__field:-webkit-autofill,
  .input__field:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
}
</style>
