<template>
  <span
    :title="[isOnline ? 'Онлайн' : 'Офлайн']"
    :class="['status', isOnline ? 'status--online' : 'status--offline']"
  />
</template>

<script>
export default {
  props: {
    isOnline: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.status {
  display: block;
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 5px;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 8px;
    height: 8px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: transparent;
  }

  &--online {
    &::after {
      background-color: #46c269;
    }
  }

  &--offline {
    &::after {
      background-color: #eb5151;
    }
  }
}
</style>
