<template>
  <div class="groups">
    <ul class="groups__list">
      <li
        v-for="group in groups"
        :key="group.id"
        class="groups__list-item"
        :class="{
          'groups__list-item--active': checkInSelected(group.id),
        }"
        @click="setSelected(group.id)"
      >
        <ProductImage :image-link="resolveAssetUrl(group.image)" :name="group.name" />
        <span>{{ group.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ProductImage from '@/components/common/ProductImage.vue'
import { resolveAssetUrl } from '@/helpers/url'
import { sendMetrics } from '@/utils/metrics'

export default {
  name: 'SidebarCategoriesFilter',
  components: {
    ProductImage,
  },
  computed: {
    ...mapGetters('filtration', ['productGroupsSelected']),
    ...mapState('product', ['groups']),
  },
  methods: {
    ...mapActions('filtration', ['setProductGroupsSelected']),
    checkInSelected(id) {
      return this.productGroupsSelected.includes(id)
    },
    setSelected(id) {
      if (this.checkInSelected(id)) {
        this.setProductGroupsSelected(this.productGroupsSelected.filter(item => item !== id))
      } else {
        this.setProductGroupsSelected([...this.productGroupsSelected, id])
        sendMetrics('filter_group', { group_name: this.groups.find(g => g.id === id)?.name })
      }
    },
    resolveAssetUrl,
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '@/assets/scss/silence/scroll-bar-decorative';

.groups {
  // Elements
  .groups__title {
    margin: 0;
  }

  .groups__list {
    display: flex;
    flex-wrap: wrap;
    max-height: 30vh;
    overflow-y: auto;
    @extend %scroll-bar-decorative-small;
  }

  .groups__list-item {
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-gap: 10px;
    align-items: center;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s;
    margin-right: 12px;
    margin-bottom: 12px;

    &:hover {
      opacity: 0.8;
    }

    // States
    &--active {
      opacity: 1;

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
