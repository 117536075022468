<template>
  <div
    class="language-select"
    :class="{
      'language-select--small': small,
    }"
  >
    <button type="button" class="language-select__button language-select__toggle" @click="showOptions = !showOptions">
      <template v-if="language === 'ru'">
        <img src="@/assets/images/icons/flag/ru.svg" class="language-select__flag" />
        <span>Русский</span>
      </template>

      <template v-else>
        <img src="@/assets/images/icons/flag/gb.svg" class="language-select__flag" />
        <span>English</span>
      </template>

      <span class="language-select__arrow icon-chevron-right"></span>
    </button>

    <transition name="fadeInOut">
      <div v-show="showOptions" class="language-select__variant">
        <button
          v-for="option in filteredOptions"
          :key="option.id"
          type="button"
          class="language-select__option"
          @click="setLanguage(option.id)"
        >
          <img :src="option.imgSrc" class="language-select__flag" />
          <span>{{ option.name }}</span>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import i18n from '@/i18n'

export default {
  name: 'LanguageSelect',
  props: {
    small: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    showOptions: false,
  }),
  computed: {
    language() {
      return i18n.locale
    },
    options() {
      return [
        {
          id: 'en',
          name: 'English',
          imgSrc: require('@/assets/images/icons/flag/gb.svg'),
        },
        {
          id: 'ru',
          name: 'Русский',
          imgSrc: require('@/assets/images/icons/flag/ru.svg'),
        },
      ]
    },
    filteredOptions() {
      return this.options.filter(o => o.id !== i18n.locale)
    },
  },
  methods: {
    setLanguage(lang) {
      i18n.locale = lang
      this.showOptions = false
      this.$emit('change', lang)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common-imports.scss';
@import '@/assets/scss/transitions.scss';

.language-select {
  position: relative;
  min-width: 171px;

  @include media-breakpoint-down(sm) {
    min-width: 150px;
  }

  &__button,
  &__option {
    display: flex;
    align-items: center;
    gap: 8px;
    background: $White;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: $Dark100;
    cursor: pointer;
    border-radius: 12px;
    border: none;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  &__toggle {
    box-shadow: 0px 4px 24px rgb(0 0 0 / 8%);
    padding: 9px 16px;
    min-width: inherit;

    @include media-breakpoint-down(sm) {
      padding: 8px 16px;
      border-radius: 20px;
    }
  }

  &__option {
    padding: 0;
  }

  &__flag {
    width: 24px;
    flex-shrink: 0;

    @include media-breakpoint-down(sm) {
      width: 16px;
    }
  }

  &__arrow {
    transform: rotate(360deg);
    color: #9d9d9d;
    margin-left: auto;
  }

  &__variant {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: $White;
    border-radius: 12px;
    padding: 12px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $Dark100;

    @include media-breakpoint-down(sm) {
      padding: 12px 16px;
    }
  }

  &--small {
    min-width: auto;
  }

  &--small &__toggle {
    padding: 0;
    width: 36px;
    height: 36px;
    justify-content: center;

    > span {
      display: none;
    }

    @include media-breakpoint-down(xs) {
      width: 30px;
      height: 30px;
    }
  }

  &--small &__variant {
    min-width: 120px;
  }
}
</style>
