<template>
  <div class="app-modal" @click="$emit('close')">
    <div class="app-modal__inner" :style="{ maxWidth: `${dialogMaxWidth}px` }" @click.stop>
      <AppModalContainer class="app-modal__body">
        <slot />
      </AppModalContainer>
    </div>
  </div>
</template>

<script>
import AppModalContainer from '../common/AppModalContainer.vue'

export default {
  name: 'AppModal',
  components: { AppModalContainer },
  emits: ['close'],
  props: {
    dialogMaxWidth: {
      type: Number,
      required: false,
      default: 600,
    },
  },
  created() {
    document.body.style.overflow = 'hidden'
  },
  beforeDestroy() {
    document.body.style.overflow = 'visible'
  },
}
</script>

<style lang="scss" scoped>
@keyframes modal-fade-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal-content-enter {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

.app-modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.33);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 16px;
  animation: modal-fade-enter 0.4s;
  overflow-y: auto;

  &__inner {
    width: 100%;
    max-height: 100%;
    animation: modal-content-enter 0.4s;
  }
}
</style>
