<template>
  <div class="user-additional-info-box">
    <div class="user-additional-info-box__about">
      <div v-if="showRegistration" class="user-additional-info-box__registration">
        {{ $t('Registered: {0}', [registered]) }}
      </div>
      <div class="user-additional-info-box__company">
        <span>{{ company.name }}</span>
        <img
          v-if="company.verified"
          class="user-additional-info-box__company-checked"
          src="@/assets/images/icons/checked.svg"
          width="12"
          height="12"
          alt="verified"
          :title="$t('Organization verified')"
        />
      </div>
      <div v-if="showLocation" class="user-additional-info-box__location" :title="locationInfo.fullCityInfo">
        <FlagIcon v-if="countryCode" :code="countryCode" class="user-additional-info-box__location-flag" />
        <span class="user-additional-info-box__location-name">{{ locationInfo.cityName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import FlagIcon from '@/components/common/FlagIcon.vue'
import cityParamsString from '@/helpers/cityParamsString'

export default {
  components: { FlagIcon },
  props: {
    registered: String,
    company: Object,
    location: Object,
    countryCode: String,
    showRegistration: {
      type: Boolean,
      default: true,
    },
    showLocation: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    locationInfo() {
      const params = {
        cityName: '',
        fullCityInfo: '',
      }

      if (!this.location) {
        params.cityName = this.$t('Unknown')
        params.fullCityInfo = this.$t('Unknown')
      } else {
        params.cityName = this.location.name
        params.fullCityInfo = cityParamsString(this.location)
      }
      return params
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.user-additional-info-box {
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  color: $DGray;
  // Elements
  .user-additional-info-box__about {
    font-size: 12px;
    padding-left: 8px;
    display: grid;
    grid-gap: 2px;
  }

  .user-additional-info-box__company {
    display: inline-flex;
    align-items: center;
    font-size: 12px;

    &-checked {
      margin-left: 4px;
    }
  }
  .user-additional-info-box__location {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 12px;

    i {
      display: block;
      width: 12px;
      height: 12px;
      position: relative;
      line-height: 1;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }

    &-flag {
      margin-right: 6px;
    }

    &-flag {
      align-self: flex-start;
    }
  }
}
</style>
