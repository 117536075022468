export default {
  order: {
    new: 'Waiting for confirmation',
    confirm: 'Confirmed',
    seller_update: 'Updated by seller',
    buyer_confirm_update: 'Confirmed by buyer',
    waiting_operator_confirm: 'Waiting for confirmation by operator',
    operator_confirm: 'Confirmed by operator',
    on_way: 'On way',
    operator_update: 'Updated by operator',
    buyer_confirm_operator_update: 'Waiting for confirmation by buyer',
    seller_confirm_operator_update: 'Waiting for confirmation by seller',
    received_dc: 'Received at pick-up point',
    sent_from_dc: 'Delivery',
    pickup_ready: 'Ready for pickup',
    collected: 'Collected',
    waiting_truck: 'Waiting for transport',
    waiting_filled_truck: 'Waiting for shipment',
    filling: 'Shipping',
    filled: 'Delivery',
    deliver: 'Delivered',
    report: 'Found disperances',
    adjustment_by_admin: 'Reviewed by operator',
    seller_correct: 'Corrected',
    hold: 'On hold',
    put_on_realization: 'Put on realization',
    return_registration: 'Return registration',
    complete: 'completed',
    cancel: 'Canceled',
    operator_cancel: 'Annulled',
    request_cancelation: 'Annul request',
  },
  orderReportedItems: {
    request_created: 'Request created',
    sent_to_seller: 'Sent to seller',
    seller_received_return: 'Received by seller',
  },
  payment: {
    expected: 'Expected',
    partial: 'Partial payment',
    paid: 'Paid',
    expired: 'Outstanding',
    dispute: 'Dispute',
    failed: 'Failed',
    canceled: 'Canceled',
  },
  application: {
    new: 'New',
    open: 'Open',
    closed: 'Closed',
  },
  orderTimeline: {
    isNew: 'Order',
    isConfirmation: 'Coordination',
    isOperatorConfirm: 'Operator confirmation',
    isGoodsInTransit: 'On way',
    isReceivedDC: 'Received at pick-up point',
    isSentFromDC: 'Delivery',
    isReadyForPickup: 'Ready for pickup',
    isCollection: 'Collection',
    isDelivery: 'Delivery',
    isObtain: 'Acceptance',
    isReport: 'Completed',
  },
  supplyRequest: {
    created: 'New',
    closed: 'Completed',
    rejected: 'Canceled',
    pending: 'On approval',
    received: 'Received',
    approved: 'Approved',
  },
  document: {
    checking: 'Checking',
    accepted: 'Accepted',
    rejected: 'Rejected',
  },
  cooperationRequest: {
    PENDING: 'New',
    REJECTED: 'Rejected',
    APPROVED: 'Approved',
    ACCEPTED: 'Accepted',
  },
}
