export const isToday = date => {
  const today = new Date()
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

export const hoursToMs = hours => hours * 60 * 60000

export const formatSecondsToTime = seconds => {
  const minutes = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0')
  const remainingSeconds = (seconds % 60).toString().padStart(2, '0')
  return `${minutes}:${remainingSeconds}`
}

export const getDaysBetweenDates = (firstDate, secondDate) => {
  return Math.abs((new Date(firstDate) - new Date(secondDate)) / 1000 / 60 / 60 / 24)
}
