import Vue from 'vue'

// id: number (seller id)
// seller: SellerProfile,
// products: SellerProduct
// createdAt: string

export const createLocalCartItem = (product, seller) => {
  return {
    id: product.seller.id,
    seller,
    products: [{ ...product }],
    createdAt: new Date().toISOString(),
    updatedAtTimestamp: new Date().getTime(),
    isReported: false,
  }
}

const getCartFromLocalStorage = () => {
  try {
    const savedCart = localStorage.getItem('localCart')

    if (savedCart) {
      return JSON.parse(savedCart)
    }

    return []
  } catch (error) {
    console.error(error)
    return []
  }
}

export default {
  namespaced: true,
  state: {
    localCartItems: getCartFromLocalStorage(),
  },
  actions: {
    addToLocalCart({ state, commit }, product) {
      const cartItemWithSameSeller = state.localCartItems.find(item => item.seller.id === product.seller.id)

      if (cartItemWithSameSeller) {
        const updated = {
          ...cartItemWithSameSeller,
          products: [...cartItemWithSameSeller.products, product],
          updatedAtTimestamp: new Date().getTime(),
        }

        commit(
          'setLocalCartItems',
          state.localCartItems.map(item => {
            if (item.seller.id === updated.seller.id) {
              return updated
            }
            return item
          }),
        )
        return
      }

      commit('setLocalCartItems', [...state.localCartItems, createLocalCartItem(product, product.seller)])
    },
    removeFromLocalCart({ state, commit }, product) {
      const productItem = state.localCartItems.find(item => item.products.some(p => p.id === product.id))

      if (!productItem) {
        return
      }

      if (productItem.products.length > 1) {
        commit(
          'setLocalCartItems',
          state.localCartItems.map(item => {
            if (item.id === productItem.id) {
              return {
                ...item,
                products: item.products.filter(p => p.id !== product.id),
                updatedAtTimestamp: new Date().getTime(),
              }
            }

            return item
          }),
        )
      } else {
        commit(
          'setLocalCartItems',
          state.localCartItems.filter(item => item.id !== productItem.id),
        )
      }
    },
    updateProductInLocalCart({ state, commit }, updatedProduct) {
      const foundItem = state.localCartItems.find(item => item.products.some(p => p.id === updatedProduct.id))
      foundItem.products = foundItem.products.map(p => (p.id === updatedProduct.id ? updatedProduct : p))
      foundItem.updatedAtTimestamp = new Date().getTime()

      commit(
        'setLocalCartItems',
        state.localCartItems.map(item => (item.id === foundItem.id ? foundItem : item)),
      )
    },
  },
  mutations: {
    setLocalCartItems(state, data) {
      state.localCartItems = data
      try {
        localStorage.setItem('localCart', JSON.stringify(data))
      } catch (error) {
        console.error(error)
      }
    },
  },
  getters: {
    localCartItemsProducts(state) {
      return state.localCartItems.flatMap(item => item.products)
    },
  },
}
