import '@/directives/clickOutsideDirective'
import notificationPlugin from '@/plugins/notification'
import { BootstrapVue } from 'bootstrap-vue'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VueMeta from 'vue-meta'
import VueGtag, { setOptions, bootstrap } from 'vue-gtag'
import Vuelidate from 'vuelidate'
import App from './App'
import { i18n } from './i18n'
import axios from './plugins/axios'
import router from './router'
import store from './store'
import Dashboard from '@/components/layout/Dashboard.vue'
import LayoutAuth from '@/components/layout/Auth.vue'
import { GOOGLE_ANALYTICS_KEY, API_URL, isProdEnv, isProdClient, isDevClient } from './constants'
import vueTopprogress from 'vue-top-progress'
import './plugins/chatra'
import { createChatra } from './plugins/chatra'
import { RR } from './plugins/retailRocket'

axios.defaults.showLoadingBar = true
Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueMeta)
Vue.use(notificationPlugin)
Vue.use(vueTopprogress)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
Vue.component('LayoutDashboard', Dashboard)
Vue.component('LayoutAuth', LayoutAuth)

Vue.use(Vuelidate)

Vue.use(VueGtag, {
  bootstrap: false,
})

if (GOOGLE_ANALYTICS_KEY.length) {
  setOptions({
    config: {
      id: GOOGLE_ANALYTICS_KEY,
      params: {
        send_page_view: false,
      },
    },
  })

  bootstrap()
}

// TODO: вынести в конфиги?
Vue.prototype.$themeUrl = process.env.THEME_URL || 'https://baza.store/wp-content/themes/site/'

Vue.prototype.$siteName = process.env.SITE_NAME || 'БАZА'

Vue.prototype.$siteDescription = process.env.SITE_DESCRIPTION || 'Онлайн B2B платформа'

Vue.prototype.$apiUrl = API_URL

Vue.mixin({
  computed: {
    console: () => console,
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')

      this.$router.push({
        name: 'home',
        replace: true,
      })
    },
  },
})

if (isProdClient) {
  createChatra()
}

RR.init()

Vue.config.devtools = !isProdEnv || isDevClient

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
