export default {
  attention: {
    common: {
      transit_eligible_alert_text: 'To make a deal, you have to {0}',
      transit_eligible_alert_link_text: 'enter into a contract with operator',
    },
    forSeller: {
      new: 'You may make changes to price and quantity or confirm this order',
      seller_update:
        'You made changes into an order and sent them to buyer. Wait for response actions. Buyer will accept changes or cancel the order',
      confirm: 'Order was confirmed. Waiting for equipment.',
      waiting_operator_confirm: 'Order is waiting for operator confirmation. After you may get started with сollecting',
      operator_confirm: 'Your order was confirmed by operator. You may start collecting the order',
      operator_update:
        'Changes were made by operator. If you agree with them, press the "confirm" button. Changes will be considered as confirmed only after agreement by both seller and buyer',
      seller_confirm_operator_update:
        "You confirmed agreement with changes by operator. After buyer's agreement goods will be shipped to distribution center.",
      sent_from_dc:
        'The goods have been shipped to the buyer from the distribution center. Buyer will confirm receipt soon.',
      on_way:
        "Goods are on way to BAZA's distributon center. After receiving them, operator will confirm or make changes into this supply.",
      buyer_confirm_update:
        'Buyer accepted your changes. Now order is waiting for operator confirmation. After, you may notify buyer about order collection.',
      collected: {
        delivery: {
          transit: 'Goods are collected. Press "Ship" when they will be shipped.',
          direct: 'Goods are collected. Press "Ship" when they will be shipped.',
        },
        pickup: {
          transit: 'Goods are collected. Press "Ship" when they will be shipped.',
          direct:
            'Goods are collected. Press "Ready for pickup" when the goods will be ready for the buyer to pick up.',
        },
      },
      report:
        "Buyer cannot accept all the goods. The operator is currently considering the buyer's claim. You will be able to see the buyer's acceptance report in detail after consideration by the operator.",
      adjustment_by_admin:
        "The operator has reviewed the buyer's claim. You can see the acceptance report below. Now you can issue a return of goods or put them on sale.",
      put_on_realization:
        "Your deal is completed. Don't forget to evaluate your cooperation with buyer, by doing this you help our service to become better.",
      return_registration:
        "Your deal is completed. Don't forget to evaluate your cooperation with buyer, by doing this you help our service to become better.",
      hold: "Buyer doesn't agree with your corrections. To complete the supply it is necessary to settle disagreements according to contract.",
      waiting_filled_truck:
        'Transport is running to you. After shipment completion add accompanying document for the goods and press "Ship". Make changes to order according to actually shipped goods quantity.',
      evaluated: 'Your deal is completed. We thank you for your evaluation.',
      report_created:
        "Don't forget to evaluate your cooperation with seller, by doing this you help our service to become better.",
      report_with_zero_discrepancies:
        "Don't forget to evaluate your cooperation with seller, by doing this you help our service to become better.",
      pickup_ready: 'The order is ready for pickup. The buyer will confirm receipt soon.',
    },
    forBuyer: {
      new: 'You may cancel the order before seller will confirm readiness to complete it.',
      waiting_operator_confirm:
        'Order is waiting for operator confirmation. After, seller may start collecting the order.',
      on_way:
        "Goods are on way to BAZA's distribution center. After receiving them, operator will confirm or make changes into this supply.",
      operator_update:
        'Operator made changes to order. Your agreement is required to proceed. After confirmation from seller, you will see the "confirm" button. Press it, if you agree with changes too.',
      seller_update:
        'Seller made changes to order list and price. You may familiarize with proposed supply and confirm by pressing "Agree" button or annul the order',
      buyer_confirm_update:
        "Seller was notified about your agreement. When goods will be collected, you'll be notified about.",
      seller_confirm_operator_update:
        'Operator made changes to this supply. Seller agreed with changes. If you also agree, confirm it by pressing "Agree" button.',
      filled: 'Press the "Receive" button to receive the goods.',
      sent_from_dc:
        'Goods were shipped to you from BAZA\'s distribution center. To receive, press the "Receive" button.',
      deliver:
        'If you found any defects in goods and want to accept only part of the supply, press "Acceptance" button and enter information about disperancies.',
      seller_correct:
        'Seller made changes to order list and price. You may familiarize with them and complete the order by pressing "Complete" button, or press the "Hold" button if you don\'t agree with changes.',
      rate_seller:
        "You successfully completed the deal. Don't forget to evaluate your cooperation with seller, by doing this you help our service to become better.",
      put_on_realization:
        "Your deal is completed. Don't forget to evaluate your cooperation with seller, by doing this you help our service to become better.",
      return_registration:
        "Your deal is completed. Don't forget to evaluate your cooperation with seller, by doing this you help our service to become better.",
      complete:
        "You successfully completed the deal. Don't forget to evaluate your cooperation with seller, by doing this you help our service to become better",
      report:
        'You have decided to return some goods. While seller is considering your claim, you may look at your acceptance report.',
      report_with_zero_discrepancies: 'The goods has been delivered. Press the "Complete" button to complete order.',
      adjustment_by_admin: 'The operator has reviewed your claim.',
      waiting_truck: 'Enter information about transport and driver, after press "Waiting for shipping"',
      // Not status, just to not display review request again
      evaluated: 'Your deal is completed. We thank you for your rating.',
      pickup_ready: 'Click the "Receive" button to receive the goods.',
    },
  },
  actionButtons: {
    request_cancelation: 'Annul',
    cancel: 'Cancel',
    seller_update: 'Update',
    buyer_confirm_update: 'Agree',
    waiting_operator_confirm: 'Confirm',
    submit: 'Confirm',
    confirm: 'Confirm',
    collected: 'Notify about collecting',
    on_way: 'Ship to DC',
    seller_confirm_operator_update: 'Agree',
    buyer_confirm_operator_update: 'Agree',
    sent_from_dc: 'Agree',
    filled: 'Ship',
    waiting_truck: 'Waiting for transport',
    waiting_filled_truck: 'Waiting for shipment',
    deliver: 'Receive',
    send: 'Send',
    seller_correct: 'Corrected',
    hold: 'Hold',
    estimate: 'Evaluate',
    seeReport: 'Show acceptance report',
    report: 'Acceptance',
    put_on_realization: 'Put on realization',
    return_registration: 'Return registration',
    complete: 'Complete',
    pickup_ready: 'Ready for pickup',
  },
  actionButtonsMessages: {
    'Provide documents': 'Provide <a href="#documents" class="link">documents</a> for delivery. ',
    'Provide truck details': 'Provide information about <a href="#truck" class="link">shipment</a>.',
  },
  orderTypes: [
    {
      id: 1,
      name: 'Application',
    },
    {
      id: 2,
      name: 'Auction',
    },
  ],
}
