import Vue from 'vue'

export const actions = {
  async listFinances({ commit, state }, params = {}) {
    const result = await Vue.$http.get('/finance/finances/', { params })

    commit('setListFinances', result.results)

    if (state.financeStatuses.length !== result.statuses.length) {
      commit('setFinanceStatuses', result.statuses)
    }

    if (state.financeTotalPages !== result.total_pages) {
      commit('setFinanceTotalPages', result.total_pages)
      commit('setCurrentPage', 1)
    }

    return result
  },
  async loadMoreFinances({ state, commit }, params) {
    const result = await Vue.$http.get('/finance/finances/', {
      params,
    })
    commit('setCurrentPage', params.page)
    commit('setListFinances', [...state.financesList, ...result.results])
  },
  async changeCurrentPage({ commit }, page) {
    commit('setCurrentPage', page)
  },
  async addPayment(context, { id, formData }) {
    await Vue.$http.post(`/finance/finances/${id}/pay/`, formData)
  },
  async loadFile(context, { id, formData }) {
    const result = await Vue.$http.post(`/finance/finances/${id}/loadfile/`, formData)
    console.log('loadFile', result)
  },
  async financeDetail({ commit }, { id, ...params }) {
    const result = await Vue.$http.get(`/finance/finances/${id}/`, { params })

    commit('setFinanceDetail', result)
  },
  async listPayments(context, params) {
    await Vue.$http.get('/finance/payments/', { params })
    // console.log(result)
  },
  async getPersonalAccount({ state, commit }, params = {}) {
    const result = await Vue.$http.get('/finance/personal_account/', params)
    if (state.transferTotalPages !== result.transfers.total_pages) {
      commit('setTransferTotalPages', result.transfers.total_pages)
    }
    commit('setTransfers', result.transfers.results)
    delete result.transfers

    commit('setPersonalAccount', result)
  },
  async loadMoreTransfers({ state, commit }, params) {
    const result = await Vue.$http.get('/finance/personal_account/', {
      params,
    })
    commit('setCurrentPage', params.page)
    commit('setTransfers', [...state.transfers, ...result.transfers.results])
  },
  async createTransferRequest(context, params) {
    const result = await Vue.$http.post('/finance/transfer_request/', params)
    console.log('createTransferRequest', result)
  },
  async createBonusesRequest({ commit }, params) {
    const result = await Vue.$http.post('/finance/transfer_bonuses/', params)
    commit('setPersonalAccount', result)
  },
  async getPayKeeperTopUpLink(_, { amount }) {
    const result = await Vue.$http.post('/finance/paykeeper_invoice/', { amount })
    return result
  },
  async updatePaykeeperInvoice(_, { invoice_id }) {
    const result = await Vue.$http.put('/finance/paykeeper_invoice/', { invoice_id })
    return result
  },
  async changeCreditLimit(_, params) {
    const result = await Vue.$http.post('/finance/change_credits/', params)
    return result
  },
  async getCreditPotential() {
    const result = await Vue.$http.post('/finance/')
  },
}
