import { TERM_TYPE } from '@/constants'
import Vue from 'vue'

export const getPickupParams = async ({
  selectedForPickupWarehouseCity,
  productsWarehouseCity,
  quantity,
  weightCoefficient,
}) => {
  // Города разделены по районам - каждый район имеет уникальный ID
  if (selectedForPickupWarehouseCity === productsWarehouseCity) {
    return {
      days: 0,
      deliveryPrice: 0,
      minimalWeight: 0,
      priceKg: 0,
    }
  }

  // price_kg означает цену или за кг, или за 1шт если товар в штуках
  const { price_kg, days, min_weight_kg } = await Vue.$http.get(
    `/order/delivery/${productsWarehouseCity}/${selectedForPickupWarehouseCity}/${TERM_TYPE.DIRECT}/delivery`,
  )

  return {
    days,
    priceKg: price_kg,
    deliveryPrice: quantity * price_kg * weightCoefficient,
    minimalWeight: min_weight_kg,
  }
}
