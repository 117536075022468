import { addRouteNameToSellerProduct } from '@/helpers/sellerProduct/addRouteNameToSellerProduct'

export const mutations = {
  setSellerProducts: (state, products) => (state.sellerProducts = products.map(addRouteNameToSellerProduct)),
  setTotalPages: (state, totalPages) => (state.totalPages = totalPages),
  setCurrentPage: (state, currentPage) => (state.currentPage = currentPage),
  setSupplyRequests: (state, requests) => (state.supplyRequests = requests),
  setSupplyRequestsTotalPages: (state, pages) => (state.supplyRequestsTotalPages = pages),
  setSupplyRequest: (state, request) => (state.supplyRequest = request),
  setProductsCities: (state, cities) => (state.productsCities = cities),
}
