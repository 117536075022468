<template>
  <div
    class="warehouse-select"
    :class="{ 'warehouse-select--expanded': showOptions, 'warehouse-select--disabled': disabled }"
  >
    <button class="warehouse-select__toggle" @click="toggle">
      <span class="warehouse-select__value-text">
        {{ selectedOptionDetails?.name || placeholder || $t('Select from the list') }}
      </span>

      <span class="warehouse-select__arrow">
        <ChevronDown :size="20" stroke="#6B7280" />
      </span>
    </button>

    <transition name="show-options">
      <ul v-show="showOptions" class="warehouse-select__options">
        <li
          v-for="option in options"
          :key="option.id"
          class="warehouse-select__option"
          :class="{ 'warehouse-select__option--selected': selectedOption === option.id }"
        >
          <button class="warehouse-select__option-button" @click="select(option)">
            <span>{{ option.name }}</span>
            <span v-if="option.advantageText" class="warehouse-select__option-advantage">
              <span class="icon icon-check"></span>
              <span>{{ option.advantageText }}</span>
            </span>
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { ChevronDown } from 'lucide-vue'

export default {
  name: 'CartWarehouseSelect',
  components: {
    ChevronDown,
  },
  props: {
    options: {
      type: Array, // [{ id: number, name: string, advantageText?: string }]
      required: true,
    },
    value: {
      type: [Number, null],
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      selectedOption: null,
      showOptions: false,
    }
  },
  computed: {
    selectedOptionDetails() {
      return this.options.find(o => o.id === this.selectedOption)
    },
  },
  watch: {
    value: {
      handler() {
        this.selectedOption = this.value
      },
      immediate: true,
    },
  },
  methods: {
    select(option) {
      this.selectedOption = option.id
      this.showOptions = false
      this.$emit('change', option.id)
    },
    toggle() {
      if (!this.disabled) {
        this.showOptions = !this.showOptions
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '@/assets/scss/silence/scroll-bar-decorative';

.warehouse-select {
  position: relative;
  z-index: 1;

  &--expanded .warehouse-select__arrow {
    transform: rotateZ(180deg);
  }

  &--disabled {
    opacity: 0.75;
  }

  &--disabled .warehouse-select__toggle {
    cursor: not-allowed;
  }

  &__toggle {
    background-color: transparent;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    padding: 8px 12px;
    display: grid;
    grid-template-columns: 1fr 16px;
    line-height: 1.5;
    font-size: 16px;
    align-items: center;
    gap: 8px;
    margin: 0;
    width: 100%;
    text-align: left;
    color: inherit;
  }

  &__arrow {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: transform 200ms ease-out;
    transform-origin: center;
  }

  &__options {
    max-height: 160px;
    border: 1px solid #d1d5db;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    background-color: $White;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #ececec;
      margin: 3px 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #737373;
    }
  }

  &__option {
    width: 100%;

    &--selected .warehouse-select__option-button {
      font-weight: 500;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $LGray3;
    }
  }

  &__option-button {
    background-color: $White;
    padding: 8px 12px;
    margin: 0;
    border: none;
    display: block;
    width: 100%;
    text-align: left;
    line-height: 1.35;
    font-size: 15px;
    color: inherit;

    &:hover {
      background-color: $LGray4;
    }
  }

  &__option-advantage {
    color: $GreenM;
    display: flex;
    align-items: center;
    font-weight: 400;

    & > .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  &__value-text {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.show-options {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }

  &-enter-active,
  &-leave-active {
    transition: 200ms ease;
  }
}
</style>
