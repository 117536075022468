import { transformToDashes } from '@/helpers/transformToDashes'

import Vue from 'vue'

export const actions = {
  async listProductGroups({ commit }, params = {}) {
    const { withAdditional, ...rest } = params

    const result = await Vue.$http.get('/product/groups/', { params: rest, showLoadingBar: false })

    let filteredGroups = result.map(group => {
      return {
        ...group,
        routeName: transformToDashes(group.name_en),
      }
    })

    if (!withAdditional) {
      filteredGroups = filteredGroups.filter(g => !g.additional)
    }

    commit('setGroups', filteredGroups)
  },
  async listProducts({ commit }, params = {}) {
    const result = await Vue.$http.get('/product/products/', params)

    const transformed = result.results.map(product => ({
      ...product,
      routeName: transformToDashes(product.name_en),
      group: {
        ...product.group,
        routeName: transformToDashes(product.group.name_en),
      },
    }))

    commit('setProducts', transformed)
  },
  async listProductsSorts({ commit }, params = {}) {
    const result = await Vue.$http.get('/product/sorts/', { params, showLoadingBar: false })
    // console.log('listProductsSorts', result)
    commit('setSorts', result)
  },
  async listProductsCategories({ commit }, params = {}) {
    const result = await Vue.$http.get('/product/categories/', { params, showLoadingBar: false })
    // console.log('listProductsCategories', result)
    commit('setCategories', result)
  },
  async listProductsPackings({ commit }, params = {}) {
    const result = await Vue.$http.get('/product/packings/', { params, showLoadingBar: false })
    // console.log('listProductsPackings', result)
    commit('setPackings', result)
  },
  async listBrands({ commit }, params = {}) {
    const result = await Vue.$http.get('/product/brands/', { params, showLoadingBar: false })
    commit('setBrands', result)
  },
  async createBrand({ commit, state }, params) {
    const result = await Vue.$http.post('/product/brands/', params)
    commit('setBrands', [result, ...state.brands])
  },
  async createGrade({ commit, state }, params) {
    const result = await Vue.$http.post('/product/sorts/', params)

    const updatedProducts = state.products.map(p => {
      if (p.id === params.product) {
        return { ...p, sort: [result, ...p.sort] }
      }
      return p
    })

    commit('setProducts', updatedProducts)
    commit('setSorts', [result, ...state.sorts])
  },
}
