import actions from './actions/en.js'
import order from './order/en.js'
import status from './status/en.js'
import units from './units/en.js'
import errors from './errors/en.js'

export default {
  actions,
  order,
  status,
  units,
  ...errors,
  Application: 'Application',
  marketProductName: '{0} {1}',
  marketProductPrice: '{0} / {1}',
  marketQuantityFrom: 'from {0} {1}',
  CurrentCountry: 'Current country: {0}',
  CurrentCity: 'Current city: {0}',
  CurrentCityOfDelivery: 'Current city of delivery: {0}',
  CurrentCountryOfDelivery: 'Current city of delivery: {0}',
  DeliveryChangedAt: '{0} {1}',
  'Not calculated': 'Not calculated',
  SurveyOfGoodsAtAddressOf: 'Survery of goods at address of {0}',
  PolicyTextOnRegister: 'By clicking on Register button you agree with ',
  PolicyTerm: 'privacy policy',
  // Это костыли, которых не должно быть, так как бек должен все перводить
  Производитель: 'Manufacturer',
  Дистрибьютор: 'Distributor',
  Импортёр: 'Importer',
  'Торговый посредник': 'Reseller',
  'Федеральный поставщик': 'Federal Supplier',
  'Фермерское хозяйство': 'Farming',
  Экспортёр: 'Exporter',
  fio: 'Full name',
  // just in case
  Экспортер: 'Exporter',
  Импортер: 'Importer',
  productModal: {
    order: 'Order',
  },
  productCategory: {
    'Высший сорт': 'Top grade',
    'Первый сорт': 'First grade',
    'Второй сорт': 'Second grade',
    Некондиция: 'Off grade',
    'Без категории': 'No grade',
  },
  productGroups: {
    Фрукты: 'Fruits',
    Овощи: 'Vegetables',
    Грибы: 'Mushrooms',
    Зелень: 'Greens',
    Ягоды: 'Berries',
    Экзотика: 'Exotics',
    Салаты: 'Salads',
  },
  productPackings: {
    Ящик: 'Packing case',
    Коробка: 'Box',
    Мешок: 'Bag',
    Сетка: 'Net',
    Стаканчик: 'Cup',
    Пакет: 'Plastic bag',
    Лоток: 'Tray',
    'Биг бэг': 'Big bag',
    Вакуум: 'Vacuum',
  },
  // чтобы не ругался на отсутствие файла локализации
  nav: {
    admin: {
      main: {
        home: 'Demands',
        market: 'Market',
        applications: 'Products',
        deliveries: 'Deliveries',
      },
      additional: {
        providers: 'Providers',
        finance: 'Finance',
        chat: 'Messages',
      },
    },
    seller: {
      main: {
        demands: 'Demands',
        products: 'My Products',
        deliveries: 'Deliveries',
      },
      additional: {
        clients: 'Clients',
        finance: 'Finance',
        chat: 'Messages',
      },
    },
    buyer: {
      main: {
        market: 'Market',
        applications: 'Applications',
        deliveries: 'Deliveries',
      },
      additional: {
        providers: 'Providers',
        finance: 'Finance',
        chat: 'Messages',
      },
    },
    support: {
      main: { market: 'Market', demands: 'Demands' },
      additional: { chat: 'Messages' },
    },
    common: {
      additional: {
        profile: 'Profile',
      },
    },
    guest: {
      main: {
        about: 'About Baza',
        forBuyers: 'Buyers',
        forSuppliers: 'Suppliers',
        forDistributors: 'Distributors',
        app: 'Mobile app',
        faq: 'FAQ',
      },
    },
  },
  footer: {
    forBuyers: 'Buyers',
    howToMakeOrder: 'How to place an order',
    paymentMethod: 'Payment methods',
    delivery: 'Delivery',
    returnPolicy: 'Return of goods',
    refund: 'Refunds',
    rulesOfTrading: 'Terms of Sale',
    docs: 'Contract and documents',
    faq: 'FAQ',
    forPartners: 'Partners',
    forSuppliers: 'For suppliers',
    forExporters: 'For exporters',
    aboutBaza: 'About BAZA',
    aboutCompany: 'About company',
    jobs: 'Jobs',
    privacyPolicy: 'Privacy policy',
    userAgreement: 'User agreement',
    mobileApp: 'Mobile app',
    downloadInAppStore: 'Download in App Store',
    downloadInGooglePlay: 'Download in Google Play',
  },
  'addapplication.title': 'Add Application',
  'applications.title': 'My Applications',
  'cart.title': 'Cart',
  'chat.title': 'Messages',
  'checkout.title': 'Checkout',
  'client.title': 'Client',
  'clients.title': 'Buyers',
  'deliveries.title': 'Deliveries',
  'delivery.title': 'Delivery #{0}',
  'finance.title': 'Finance',
  'login.title': 'Login',
  'market.title': 'Product Market',
  'marketSearch.title': 'Search',
  'myproducts.title': 'My Products',
  'pagenotfound.title': 'Page Not Found',
  'profileedit.title': 'Edit Profile',
  'provider.title': 'Provider',
  'providers.title': 'Providers',
  'pendingOrders.title': 'Pending orders',
  'register.title': 'Registration',
  'standarts.title': 'Standards',
  'updatepassword.title': 'Change Password',
  'demands.title': 'Demands',
  'shorts.kg': 'Kg',
  'shorts.pack': 'Pack',
  'shorts.piece': 'Pcs',
  'shorts.liter': 'L',
  'header.signIn': 'Sign in',
  'header.signUp': 'Sign up',
}
