export const getters = {
  cartItemsProducts: state =>
    state.cartItems.reduce((allProducts, item) => (allProducts = allProducts.concat(item.products)), []),
  allCartItemsProducts: state => {
    return [...state.cartItems, ...state.combinedCartItems].reduce(
      (allProducts, item) => (allProducts = [...allProducts, ...item.products]),
      [],
    )
  },
  getPriceWithPromocode: () => (promocode, price) => {
    if (!promocode?.is_active) {
      return price
    }

    if (promocode.discount_percentage) {
      return price - (price * promocode.discount_percentage) / 100
    }

    if (promocode.discount_fixed) {
      return Math.max(price - promocode.discount_fixed, 0)
    }

    return price
  },
  getProductPrice: (_, getters) => product => {
    return getters.getPriceWithPromocode(product.promo_code, product.price)
  },
  getProductTotalPrice: (_, getters) => product => {
    const price = getters.getPriceWithPromocode(product.promo_code, product.price)
    return price * product.weight
  },
  getItemTotalProductsPriceAfterDiscounts: (_, getters) => products => {
    return products.reduce((total, product) => {
      return (total += getters.getProductTotalPrice(product))
    }, 0)
  },
}
