import i18n from '@/i18n'
import { totalProductsWeight } from './totalProductsWeight'
import formatPrice from './formatPrice'

/**
 * @returns { string } Строка с итоговым количеством позиций, весом, и ценой товаров
 */

export default ({ products, totalPrice, currency = 'RUB' }) => {
  const productsWeight = totalProductsWeight(products)

  return i18n.t('Summary {0} with total weight of {1} kg and total price {2}', [
    i18n.tc('units.position', products.length),
    new Intl.NumberFormat('ru-RU').format(productsWeight),
    formatPrice(totalPrice, currency),
  ])
}
