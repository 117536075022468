import { transliterate } from '../transliterate'

export const addRouteNameToSellerProduct = product => {
  const title = `${product.product.name_ru || ''} ${product.sort?.name || ''}`

  return {
    ...product,
    routeName: `${transliterate(title)}-${product.id}`,
  }
}
