import Vue from 'vue'

const mergeApiCartItemsToLocalStorage = apiCartItems => {
  try {
    const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || []
    const localCartItemsMap = new Map(localCartItems.map(item => [item.id, item]))

    const mergedCartItems = apiCartItems.map(item => {
      if (localCartItemsMap.has(item.id)) {
        return {
          ...item,
          updatedAtTimestamp: localCartItemsMap.get(item.id).updatedAtTimestamp,
          isReported: localCartItemsMap.get(item.id).isReported,
        }
      }

      return { ...item, updatedAtTimestamp: new Date().getTime(), isReported: false }
    })

    localStorage.setItem('cartItems', JSON.stringify(mergedCartItems))
  } catch (e) {
    console.error(e)
  }
}

const addCartItemToLocalStorage = newItem => {
  try {
    let items = JSON.parse(localStorage.getItem('cartItems')) || []
    items = items.filter(item => item.id !== newItem.id)
    items.push({ ...newItem, updatedAtTimestamp: new Date().getTime(), isReported: false })
    localStorage.setItem('cartItems', JSON.stringify(items))
  } catch (e) {
    console.error(e)
  }
}

const removeCartItemsFromLocalStorage = itemIds => {
  try {
    let items = JSON.parse(localStorage.getItem('cartItems')) || []
    items = items.filter(item => !itemIds.includes(item.id))
    localStorage.setItem('cartItems', JSON.stringify(items))
  } catch (e) {
    console.error(e)
  }
}

export const updateCartItemInLocalStorage = updatedItem => {
  try {
    let items = JSON.parse(localStorage.getItem('cartItems')) || []
    items = items.map(item => {
      if (item.id === updatedItem.id) {
        return { ...updatedItem, updatedAtTimestamp: new Date().getTime() }
      }
      return item
    })
    localStorage.setItem('cartItems', JSON.stringify(items))
  } catch (e) {
    console.error(e)
  }
}

export const actions = {
  async listCartItems({ state, commit, rootGetters }, { showLoadingBar = false, ...params }) {
    const result = await Vue.$http.get('/cart/items/', { showLoadingBar, params })
    commit('setCartItems', result.results)
    commit('setCount', result.count)
    commit('SET_COMBINED_CART_ITEMS', [])

    const isBuyer = rootGetters['user/isBuyer']

    if (isBuyer) {
      mergeApiCartItemsToLocalStorage(result.results)
    }

    if (state.totalPages !== result.total_pages) {
      commit('setTotalPages', result.total_pages)
      commit('setCurrentPage', 1)
    }
  },
  async getCartItem(_, { id }) {
    const result = await Vue.$http.get(`/cart/items/${id}/`)
    return result
  },
  async addToCart({ commit, getters }, params) {
    const alreadyInCart = getters.cartItemsProducts.some(
      item => item && item.seller_product.id === params.seller_product,
    )

    if (!alreadyInCart) {
      const result = await Vue.$http.post('/cart/add/', params) // cartItem
      commit('addToCart', result)
      addCartItemToLocalStorage(result)
    }
  },
  async editCartProduct({ commit }, { product_id, ...params }) {
    const result = await Vue.$http.patch(`/cart/products/${product_id}`, params) // cartItem
    commit('saveCart', result)
    updateCartItemInLocalStorage(result)
  },
  async removeCartProduct({ commit }, { product_id }) {
    const result = await Vue.$http.delete(`/cart/products/${product_id}`) // cartItem
    commit('saveCart', result)
    updateCartItemInLocalStorage(result)
  },
  async removeCartItem({ commit }, { id }) {
    await Vue.$http.delete(`/cart/items/${id}/`)
    commit('removeCartItem', id)
    removeCartItemsFromLocalStorage([id])
  },
  async clearCart({ commit, state }) {
    await Vue.$http.delete(`/cart/clear/`)
    const ids = new Set([])

    ;[...state.cartItems, ...state.combinedCartItems].forEach(item => {
      ids.add(item.id)
    })

    removeCartItemsFromLocalStorage([...ids])
    commit('setCartItems', [])
    commit('SET_COMBINED_CART_ITEMS', [])
  },
  changePage({ commit }, page) {
    commit('setCurrentPage', page)
  },
  async editCartItem({ commit }, { id, ...params }) {
    const result = await Vue.$http.patch(`/cart/items/${id}/edit/`, params) // cartItem
    commit('saveCart', result)
    updateCartItemInLocalStorage(result)
    return result
  },
  addToCombinedCartItems({ commit, state }, item) {
    commit('SET_COMBINED_CART_ITEMS', [...state.combinedCartItems, item])
    commit(
      'setCartItems',
      state.cartItems.filter(i => i.id !== item.id),
    )
  },
  removeFromCombinedItems({ commit, state }, item) {
    commit(
      'SET_COMBINED_CART_ITEMS',
      state.combinedCartItems.filter(i => i.id !== item.id),
    )
    commit('setCartItems', [...state.cartItems, item])
  },
  setCombinedCartItems({ commit }, items) {
    commit('setCombinedCartItems', items)
  },
}
