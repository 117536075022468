import i18n from '@/i18n'

export const warehouseAddressString = (warehouseObj, params = { withRegion: true, withAddress: true }) => {
  if (!warehouseObj) {
    return i18n.t('Not specified')
  }

  const cityName = params.withRegion === false ? warehouseObj.city.name.split(',')[0] : warehouseObj.city.name
  const address = warehouseObj.address

  return cityName + (params.withAddress === false ? '' : `, ${address}`)
}
