import Chatra from '@chatra/chatra'

export const createChatra = (setupParams = {}) => {
  Chatra('init', {
    ID: 'PWbyJTTNh6YTqQWoF',
    setup: {
      colors: {
        buttonBg: '#2A9F3A',
      },
      zIndex: 100,
      ...setupParams,
    },
  })
}

export const hideChatra = () => {
  if (window.Chatra) {
    window.Chatra('hide')
  }
}

export const showChatra = () => {
  if (window.Chatra) {
    window.Chatra('show')
  }
}
