<template>
  <div class="dashboard">
    <Sidebar v-if="!hideSidebar" class="dashboard__sidebar" />
    <Header class="dashboard__header" />
    <main class="dashboard__main">
      <slot />
    </main>
    <AppFooter class="dashboard__footer" />
  </div>
</template>

<script>
import Sidebar from '@/components/common/Sidebar.vue'
import Header from '@/components/common/Header.vue'
import AppFooter from '../common/AppFooter.vue'

export default {
  name: 'Dashboard',
  components: {
    Sidebar,
    Header,
    AppFooter,
  },
  computed: {
    hideSidebar() {
      return this.$route.meta.hideSidebar === true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '@/assets/scss/silence/scroll-bar-decorative';

.dashboard {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto auto;
  grid-template-areas: 'header' 'main' 'sidebar' 'footer';
  max-width: 100%;
  min-height: 100vh;

  @include media-breakpoint-up(md) {
    grid-template-columns: min-content 1fr;
    grid-template-rows: max-content 1fr auto;
    grid-template-areas:
      'sidebar header'
      'sidebar main'
      'sidebar footer';
  }

  .dashboard__sidebar {
    grid-area: sidebar;
    align-items: start;
    position: sticky;
    bottom: 0;
    z-index: 150;

    @include media-breakpoint-up(md) {
      max-height: 100vh;
      top: 0;
    }
  }

  .dashboard__header {
    grid-area: header;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 99;
  }

  .dashboard__main {
    grid-area: main;
    background-color: $LGray4;
    position: relative;
    min-height: calc(100vh - $size-header-height);

    @include media-breakpoint-down(xs) {
      min-height: calc(100vh - $size-header-height-mobile);
    }
  }

  .dashboard__footer {
    grid-area: footer;
    background-color: $AsphaltGray;
    overflow-x: auto;
    @extend %scroll-bar-decorative-small;
  }
}
</style>
