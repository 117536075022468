<template>
  <div class="cart-sidebar">
    <div class="cart-sidebar__header">
      <p class="cart-sidebar__lead">
        {{ $t('cart.title') }}
      </p>

      <button type="button" class="button-reset" @click="hide">
        <X :size="20" />
      </button>
    </div>

    <div class="cart-sidebar__content">
      <ul v-if="localCartItems.length" class="cart-items">
        <LocalCartItem
          v-for="item in localCartItems"
          :key="item.id"
          :item="item"
          class="cart-items__item"
          @update-product="updateProduct"
          @remove-product="removeProduct"
        />
      </ul>

      <div v-else class="cart-sidebar__empty">
        <span>
          {{ $t('Cart is empty') }}
        </span>
      </div>
    </div>

    <div v-if="localCartItemsProducts.length" class="cart-sidebar__footer">
      <router-link :to="{ name: 'login', query: { redirect: '/cart' } }" class="cart-sidebar__cta">
        <span class="cart-sidebar__cta-btn" @click="sendMetrics('click_checkout_unauthorized')">
          {{ $t('Place an order') }}
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/helpers'
import { X } from 'lucide-vue'
import { mapGetters, mapState } from 'vuex'
import formatPrice from '@/helpers/filters/formatPrice'
import summaryString from '@/helpers/filters/summaryString'
import { ECOMMERCE_ACTION_TYPE, pushEcommerce, sendMetrics } from '@/utils/metrics'
import LocalCartItem from './LocalCartItem.vue'

export default {
  name: 'CartSidebar',
  components: {
    X,
    LocalCartItem,
  },
  computed: {
    ...mapState('localCart', ['localCartItems']),
    ...mapState('profile', ['systemSellers']),
    ...mapGetters('cart', ['getProductTotalPrice']),
    ...mapGetters('localCart', ['localCartItemsProducts']),
    totalCartPrice() {
      return this.localCartItemsProducts.reduce((acc, product) => {
        return (acc += this.getProductTotalPrice(product))
      }, 0)
    },
  },
  created() {
    if (!this.systemSellers.length) {
      this.$store.dispatch('profile/listAllSystemSellers')
    }
  },
  methods: {
    formatPrice,
    summaryString,
    sendMetrics,
    hide() {
      eventBus.$emit('showCartSidebar', false)
    },
    updateProduct(product, newQuantity) {
      this.$store.dispatch('localCart/updateProductInLocalCart', { ...product, weight: newQuantity })
    },
    removeProduct(product) {
      this.$store.dispatch('localCart/removeFromLocalCart', product)

      pushEcommerce({
        actionType: ECOMMERCE_ACTION_TYPE.remove,
        products: [{ ...product, quantity: product.weight }],
        list: 'Корзина',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common-imports.scss';

.cart-sidebar {
  padding: 16px;
  background-color: $White;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  gap: 16px;
  height: 100%;

  &__header {
    font-size: 20px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 1fr 20px;
    gap: 8px;
  }

  &__cta {
    display: block;
    background-color: $GreenM;
    border-radius: 12px;
    margin-bottom: 12px;
    font-weight: 500;
    color: $White;
    text-align: center;

    &-btn {
      display: block;
      padding: 12px;
    }
  }

  &__empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__footer {
    border-top: 1px solid $LGray6;
    padding-top: 12px;
  }
}

.cart-items {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}
</style>
