var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"checkbox-item",class:{
    'checkbox-item--s': _vm.size === 's',
    'checkbox-item--m': _vm.size === 'm',
    'checkbox-item--l': _vm.size === 'l',
    'checkbox-item--disabled': _vm.disabled,
    'checkbox-item--darkmode': _vm.darkmode,
  },attrs:{"for":_vm.id}},[_c('input',{staticClass:"checkbox-item__field",attrs:{"id":_vm.id,"name":_vm.name,"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":_vm.checkboxValue},on:{"input":_vm.toggle}}),_c('span',{staticClass:"checkmark"}),_vm._t("label",function(){return [(_vm.label)?_c('span',{staticClass:"checkbox-item__label"},[_vm._v(_vm._s(_vm.label))]):_vm._e()]},{"label":_vm.label})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }