<template>
  <div id="app">
    <vue-topprogress
      ref="topProgress"
      :color="inResponsiveSizes(['xs', 'sm']) ? '#ffcc4a' : '#1d9f3a'"
      color-shadow
      :height="3"
    ></vue-topprogress>
    <Notification />
    <router-view />
    <GlobalModal />
    <FloatingActionRequest />

    <b-sidebar
      id="cart-sidebar"
      v-model="showCartSidebar"
      shadow
      right
      no-header
      width="340px"
      backdrop
      sidebar-class="market__cart-sidebar"
    >
      <CartSidebar />
    </b-sidebar>
  </div>
</template>

<script>
import { toRepresent } from '@/helpers'
import inResponsiveSizes from '@/helpers/filters/inResponsiveSizes'
import { eventBus } from '@/helpers/eventBus'
import { mapActions, mapGetters, mapState } from 'vuex'
import GlobalModal from '@/components/modals/GlobalModal.vue'
import Notification from '@/components/common/Notification.vue'
import FloatingActionRequest from './components/common/FloatingActionRequest.vue'
import { inviteUserToSurvey } from './funcs/userPreferencesSurvey'
import { createOrUpdateUserInfoInGoogleSheet } from './funcs/googleSheet'
import { RR } from './plugins/retailRocket'
import { sendMetrics } from './utils/metrics'
import { updateCartItemInLocalStorage } from './store/modules/cart/actions'
import CartSidebar from '@/components/cart/CartSidebar.vue'

export default {
  name: 'App',
  components: {
    GlobalModal,
    Notification,
    FloatingActionRequest,
    CartSidebar,
  },
  data: () => ({
    cartRefetchInterval: null,
    abandonedCartItemsTrackerInterval: null,
    abandonedLocalCartItemsTrackerInterval: null,
    showCartSidebar: false,
  }),
  computed: {
    ...mapState('auth', ['token']),
    ...mapState('user', ['currentUser']),
    ...mapGetters('user', ['isBuyer', 'isSeller']),
    ...mapState('localCart', ['localCartItems']),
  },
  watch: {
    'currentUser.id': {
      handler() {
        if (this.currentUser.id) {
          clearInterval(this.abandonedLocalCartItemsTrackerInterval)

          try {
            createOrUpdateUserInfoInGoogleSheet(this.currentUser)
            inviteUserToSurvey()
          } catch (e) {
            console.error(e)
          }

          if (this.isBuyer && !!this.currentUser.email) {
            try {
              RR.setUserInfo(this.currentUser.email, this.currentUser.name)
            } catch (e) {
              console.error(e)
            }
          }

          if (this.isBuyer) {
            this.handleCartItemsForBuyer()
          }

          if (this.isSeller) {
            this.handleCartItemsForSeller()
          }
        } else {
          this.handleCartItemsForGuest()
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.setScreenSize(this.getRealSize())
    window.addEventListener('resize', () => {
      this.setScreenSize(this.getRealSize())
    })

    // Нужно дождаться пока не выполнится первая проверка
    await this.$store.dispatch('auth/refreshToken')
    // Эти юниты нужны везде так что грузим их при старте приложения
    await this.$store.dispatch('misc/loadWeightUnits')
  },
  mounted() {
    eventBus.$on('showLoadingBar', v => {
      v ? this.$refs.topProgress?.start() : this.$refs.topProgress?.done()
    })

    let that = this
    eventBus.$on('error', e => {
      const errorText = toRepresent(e.message || e)

      this.$notif({
        success: false,
        title: that.$t('Something went wrong'),
        body: that.$te(errorText) ? that.$t(errorText) : errorText,
      })
    })

    eventBus.$on('showCartSidebar', val => {
      this.showCartSidebar = val
    })
  },
  beforeDestroy() {
    clearInterval(this.cartRefetchInterval)
    clearInterval(this.abandonedCartItemsTrackerInterval)
    clearInterval(this.abandonedLocalCartItemsTrackerInterval)
  },
  methods: {
    ...mapActions(['setScreenSize']),
    getRealSize() {
      let height = window.innerHeight
      let width = window.innerWidth
      return [width, height]
    },
    inResponsiveSizes,
    getCartItems() {
      try {
        this.$store.dispatch('cart/listCartItems', {})
      } catch (e) {
        console.error(e)
      }
    },
    trackAbandonedCartItems() {
      try {
        const cartItems = JSON.parse(localStorage.getItem('cartItems')) || []
        const currentTime = new Date().getTime()
        const abandonedCartItems = cartItems.filter(item => currentTime - item.updatedAtTimestamp > 15 * 60 * 1000) // 15 min

        if (!abandonedCartItems.length) {
          return
        }

        for (let item of abandonedCartItems) {
          if (item.isReported === false) {
            sendMetrics('abandoned_cart', { item_id: `${item.id}` })
            updateCartItemInLocalStorage({ ...item, isReported: true })
          }
        }
      } catch (e) {}
    },
    trackAbandonedLocalCartItems() {
      try {
        const currentTime = new Date().getTime()
        const abandonedLocalCartItems = (this.localCartItems || []).filter(
          item => currentTime - item.updatedAtTimestamp > 15 * 60 * 1000,
        ) // 15 min

        if (!abandonedLocalCartItems.length) {
          return
        }

        for (let item of abandonedLocalCartItems) {
          if (item.isReported === false) {
            sendMetrics('abandoned_cart', { item_id: `${item.id}` })

            this.$store.commit(
              'localCart/setLocalCartItems',
              this.localCartItems.map(cartItem => {
                if (cartItem.id === item.id) {
                  return { ...cartItem, isReported: true }
                }

                return cartItem
              }),
            )
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    handleCartItemsForGuest() {
      this.abandonedLocalCartItemsTrackerInterval = setInterval(this.trackAbandonedLocalCartItems, 60 * 1000) // 1 min
    },
    handleCartItemsForBuyer() {
      this.getCartItems()
      this.abandonedCartItemsTrackerInterval = setInterval(this.trackAbandonedCartItems, 60 * 1000) // 1 min
    },
    handleCartItemsForSeller() {
      this.getCartItems()
      this.cartRefetchInterval = setInterval(this.getCartItems, 60 * 1000) // 1min
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/index';
</style>
