<template>
  <div class="logo" :class="{ 'logo--dynamic': changeColorOnMobile }">
    <svg viewBox="0 0 80 22" preserveAspectRatio="xMidYMin" xmlns="http://www.w3.org/2000/svg">
      <text y="20">{{ $siteName }}</text>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    changeColorOnMobile: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 70px;
  max-width: 80px;

  > svg {
    font-weight: 700;
    font-size: 30px;
    line-height: 1;
    fill: $GreenM;
    -webkit-transition: 0.3s ease all 0s;
    -o-transition: 0.3s ease all 0s;
    transition: 0.3s ease all 0s;
    width: inherit;
    height: inherit;
  }

  &--dynamic {
    @include media-breakpoint-down(sm) {
      > svg {
        fill: $White;
      }
    }
  }
}
</style>
