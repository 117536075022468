<template>
  <div class="categories">
    <h5 class="categories__title">
      {{ $t('Categories') }}
    </h5>

    <ul class="categories__list">
      <li
        v-for="category in categories"
        :key="category.id"
        class="categories__list-item"
        :class="{
          'categories__list-item--active': checkInSelected(category.id),
        }"
        @click="setSelected(category.id)"
      >
        <ProductCategory :id="category.id" />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ProductCategory from '@/components/common/ProductCategory.vue'

export default {
  name: 'SidebarCategoriesFilter',
  components: {
    ProductCategory,
  },
  computed: {
    ...mapGetters('filtration', ['productCategoriesSelected']),
    ...mapState('product', ['categories']),
  },
  methods: {
    ...mapActions('filtration', ['setProductCategoriesSelected']),
    checkInSelected(id) {
      return this.productCategoriesSelected.includes(id)
    },
    setSelected(id) {
      if (this.checkInSelected(id)) {
        this.setProductCategoriesSelected(this.productCategoriesSelected.filter(item => item !== id))
      } else {
        this.setProductCategoriesSelected([...this.productCategoriesSelected, id])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.categories {
  // Elements
  .categories__title {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .categories__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
  }
  .categories__list-item {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s;
    margin: 0 4px 4px;

    &:hover {
      opacity: 0.6;
    }

    // States
    &--active {
      opacity: 1;

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
