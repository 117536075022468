import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import auth from './modules/auth'
import cart from './modules/cart'
import chat from './modules/chat'
import demand from './modules/demand'
import demandResult from './modules/demandResult'
import finance from './modules/finance'
import filtration from './modules/filtration'
import misc from './modules/misc'
import order from './modules/order'
import product from './modules/product'
import profile from './modules/profile'
import profileProduct from './modules/profileProduct'
import user from './modules/user'
import paykeeper from './modules/paykeeper'
import storage from './modules/storage'
import notification from './modules/notification'
import addSupplyRequest from './modules/addSupplyRequest'
import distributor from './modules/distributor'
import tochka from './modules/tochka'
import marketCatalog from './modules/marketCatalog'
import localCart from './modules/localCart'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    filtration,
    auth,
    misc,
    profile,
    product,
    demand,
    demandResult,
    profileProduct,
    cart,
    order,
    chat,
    finance,
    user,
    paykeeper,
    storage,
    notification,
    addSupplyRequest,
    distributor,
    tochka,
    marketCatalog,
    localCart,
  },
})
