export const mutations = {
  addToCart: (state, data) => {
    state.cartItems = state.cartItems.filter(item => item.id !== data.id)
    state.cartItems.push(data)
  },
  setCartItems: (state, data) => (state.cartItems = data),
  clearCart: state => (state.cartItems = []),
  removeCartItem: (state, id) => {
    let newCartItems = state.cartItems.filter(item => item.id !== id)
    let newCombinedCartitems = state.combinedCartItems.filter(item => item.id !== id)

    if (newCombinedCartitems.length < 2) {
      newCartItems = [...newCartItems, ...newCombinedCartitems]
      newCombinedCartitems = []
    }

    state.combinedCartItems = newCombinedCartitems
    state.cartItems = newCartItems
  },
  saveCart: (state, data) => {
    if (state.combinedCartItems.some(i => i.id === data.id)) {
      state.combinedCartItems = state.combinedCartItems.map(item => (item.id === data.id ? data : item))
      return
    }

    state.cartItems = state.cartItems.map(item => (item.id === data.id ? data : item))
  },
  setTotalPages: (state, pages) => {
    state.totalPages = pages
  },
  setCurrentPage: (state, page) => {
    state.currentPage = page
  },
  setCount: (state, newCount) => {
    state.count = newCount
  },
  SET_COMBINED_CART_ITEMS: (state, payload) => {
    state.combinedCartItems = payload
  },
}
