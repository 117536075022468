<template>
  <li class="cart-item">
    <div class="cart-item__seller">
      <UserInfoBox
        class="cart-item__account"
        :name="item.seller.user.name"
        :pic="item.seller.user.photo"
        :rating="item.seller.rating"
        :is-online="item.seller.user.is_online"
        :show-reviews="false"
        :small-image="true"
        :show-large-photo-on-click="false"
      />

      <UserAdditionalInfoBox
        :company="{
          name: item.seller.organization.short_title,
          verified: item.seller.organization.verified,
        }"
        :location="item.seller.default_store?.city"
        :show-registration="false"
        class="cart-item__user-additional"
      />
    </div>

    <ul class="cart-item__products">
      <li v-for="product in item.products" :key="product.id" class="cart-item__product cart-product">
        <div class="cart-product__name">
          <ProductImage :name="product.product.name_en" />
          <span>{{ product.product.name }} {{ product.sort.name }}, {{ product.country.name }}</span>

          <button class="button-reset cart-product__remove" @click="$emit('remove-product', product)">
            <Trash :size="16" />
          </button>
        </div>

        <div class="cart-product__quantity-and-price">
          <div class="cart-product__quantity">
            <Counter
              v-model="product.weight"
              color="gray"
              :step="1"
              :min="isPcsOrPkg(product.wt_unit) ? product.net_weight / product.one_item_weight : product.net_weight"
              class="cart-product__quantity-field"
              @input="$emit('update-product', product, $event)"
            />

            <span class="cart-product__measure">
              {{ $t(`units.weight.${product.wt_unit.name}`) }}
            </span>
          </div>

          <p class="cart-product__price">
            {{ formatPrice(getProductPrice(product), product.price_unit_text) }}/{{
              $t(`units.weight.${product.wt_unit.name}`)
            }}
          </p>
        </div>

        <div class="cart-product__total">
          {{ $t('Total: {0}', [formatPrice(getProductTotalPrice(product), product.price_unit_text)]) }}
        </div>
      </li>
    </ul>

    <div class="cart-item__delivery">
      <div class="cart-item__delivery-options">
        <button
          :class="{
            'button-reset cart-item__delivery-option': true,
            'button-reset cart-item__delivery-option--active': selectedDeliveryMethod === DELIVERY_TYPE.PICKUP,
          }"
          @click="selectedDeliveryMethod = DELIVERY_TYPE.PICKUP"
        >
          {{ $t('Pickup') }}
        </button>

        <button
          :class="{
            'button-reset cart-item__delivery-option': true,
            'button-reset cart-item__delivery-option--active': selectedDeliveryMethod === DELIVERY_TYPE.DELIVERY,
          }"
          @click="selectedDeliveryMethod = DELIVERY_TYPE.DELIVERY"
        >
          {{ $t('Delivery') }}
        </button>
      </div>

      <CartWarehouseSelect
        v-if="selectedDeliveryMethod === DELIVERY_TYPE.PICKUP"
        :value="selectedWarehouse"
        :options="warehouseSelectOptions"
        :disabled="!warehouseSelectOptions.length"
        :placeholder="$t('Select warehouse for pickup')"
        class="cart-item__location-select"
        @change="selectedWarehouse = $event"
      />

      <div
        v-else-if="selectedDeliveryMethod === DELIVERY_TYPE.DELIVERY && productsByArea.size === 1"
        class="cart-item__location-search"
      >
        <vue-bootstrap-typeahead
          v-model="cityQuery"
          input-class="cart-item__location-input"
          :data="cities"
          :serializer="s => s.name"
          :placeholder="$t(`Start typing the city name`)"
          @hit="handleSelectCity"
          @input="citySearchRequest"
        />
      </div>

      <div
        v-else-if="selectedDeliveryMethod === DELIVERY_TYPE.DELIVERY && productsByArea.size > 1"
        class="cart-item__delivery-ineligible"
      >
        <p>
          {{ $t('Delivery is not available, collect items in one city/area') }}
        </p>

        <div class="cart-item__keep-products-in">
          <button
            v-for="[key, value] in productsByArea"
            :key="key"
            type="button"
            class="button-reset cart-item__keep-in"
            @click="keepByArea(key)"
          >
            <span>{{
              $t('Keep products in: {0}', [warehouseAddressString(value[0].warehouse_address, { withRegion: false })])
            }}</span>
          </button>
        </div>
      </div>

      <div v-if="totalDeliveriesPrice > 0" class="cart-item__delivery-price">
        <b-alert
          v-if="selectedDeliveryMethod === DELIVERY_TYPE.PICKUP"
          variant="secondary"
          show
          class="cart-item__delivery-notice"
        >
          <Info :size="20" />
          {{ $t('Goods will be delivered from current to selected warehouse for additional price') }}
        </b-alert>

        <p>{{ $t('Delivery cost') }}: {{ formatPrice(totalDeliveriesPrice) }}</p>
      </div>

      <b-alert v-if="hasCurrentError" show variant="danger" class="cart-item__error"
        >{{ Object.values(error).find(v => v !== null) }}
      </b-alert>
    </div>

    <div class="cart-item__footer">
      {{
        summaryString({
          products: item.products,
          totalPrice: itemTotalPrice + totalDeliveriesPrice,
        })
      }}
    </div>
  </li>
</template>

<script>
import UserInfoBox from '@/components/common/UserInfoBox.vue'
import UserAdditionalInfoBox from '@/components/common/UserAdditionalInfoBox.vue'
import ProductImage from '@/components/common/ProductImage.vue'
import Counter from '@/components/form/Counter.vue'
import { Trash } from 'lucide-vue'
import formatPrice from '@/helpers/filters/formatPrice'
import { mapGetters } from 'vuex'
import { DELIVERY_TYPE, initialDeliveryInfo, RUSSIA_COUNTRY_ID } from '@/constants'
import { filterAvailableForPickupWarehouses } from '@/helpers/cart/filterAvailableForPickupWarehouses'
import CartWarehouseSelect from '../common/CartWarehouseSelect.vue'
import { getPickupParams } from '@/helpers/getPickupParams'
import { getDeliveryParams } from '@/helpers/getDeliveryParams'
import { totalProductsWeight } from '@/helpers/filters/totalProductsWeight'
import { Info } from 'lucide-vue'
import { debounce } from 'lodash'
import { cartProductsByCityOrDistrict } from '@/helpers/cart/cartProductsByCityOrDistrict'
import { warehouseAddressString } from '@/helpers/warehouseAddressString'
import summaryString from '@/helpers/filters/summaryString'

const initialErrorState = {
  deliveryUnavailable: null,
  invalidWeight: null,
}

export default {
  name: 'LocalCartItem',
  components: {
    UserInfoBox,
    UserAdditionalInfoBox,
    ProductImage,
    Trash,
    Counter,
    CartWarehouseSelect,
    Info,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedDeliveryMethod: null,
    selectedWarehouse: null,
    deliveryInfo: initialDeliveryInfo,
    weightCoefficient: 0,
    selectedCityId: null,
    // city search
    cityQuery: '',
    // other
    DELIVERY_TYPE,
    error: initialErrorState,
  }),
  computed: {
    ...mapGetters('misc', ['isPcsOrPkg', 'cities']),
    ...mapGetters('cart', ['getProductPrice', 'getProductTotalPrice']),
    ERROR_STRINGS() {
      const isDelivery = this.selectedDeliveryMethod === DELIVERY_TYPE.DELIVERY

      return {
        NO_WAREHOUSE_SELECTED: isDelivery
          ? this.$t('Please select a delivery address')
          : this.$t('Please select warehouse for pickup'),
        DELIVERY_UNAVAILABLE: isDelivery
          ? this.$t(
              'Delivery to selected destination is currently unavailable. Please choose another one and try again',
            )
          : this.$t('Pickup at selected destination is currently unavailable. Please choose another one and try again'),
        INVALID_WEIGHT: this.$t('Total weight should be at least {0} kg.', [this.deliveryInfo?.min_weight_kg]),
      }
    },
    hasCurrentError() {
      return Object.values(this.error).some(v => v !== null)
    },
    itemTotalPrice() {
      return this.item.products.reduce((acc, product) => {
        acc += this.getProductTotalPrice(product)
        return acc
      }, 0)
    },
    pickupWarehouses() {
      return filterAvailableForPickupWarehouses(this.item.seller.is_system_seller, this.item.products)
    },
    productsWarehouse() {
      return this.item.seller.is_system_seller
        ? this.item.products[0].warehouse_address
        : this.item.seller.term_condition.warehouse_address
    },
    warehouseSelectOptions() {
      return this.pickupWarehouses.map(w => ({
        id: w.id,
        name: `${w.city.name}, ${w.address}`,
        advantageText: w.id === this.productsWarehouse.id ? this.$t('Products are here') : undefined,
      }))
    },
    selectedWarehouseDetails() {
      return this.selectedDeliveryMethod === DELIVERY_TYPE.PICKUP
        ? this.pickupWarehouses.find(w => w.id === this.selectedWarehouse)
        : { city: { id: this.selectedCityId } }
    },
    totalWeight() {
      return totalProductsWeight(this.item.products)
    },
    totalDeliveriesPrice() {
      const deliverySum = this.totalWeight * this.deliveryInfo.price_kg * this.weightCoefficient
      return deliverySum || 0
    },
    productsByArea() {
      return cartProductsByCityOrDistrict(this.item.products)
    },
  },
  watch: {
    selectedDeliveryMethod() {
      this.error = {
        ...this.error,
        deliveryUnavailable: null,
        invalidWeight: null,
      }

      this.selectedWarehouse = null
      this.selectedCityId = null
      this.deliveryInfo = initialDeliveryInfo
    },
    selectedWarehouse() {
      if (this.selectedWarehouse !== null) {
        this.getDeliveryInfo()
      }
    },
    selectedCityId() {
      if (this.selectedCityId !== null) {
        this.getDeliveryInfo()
      }
    },
    totalWeight() {
      this.getDeliveryInfo()
    },
  },
  methods: {
    formatPrice,
    warehouseAddressString,
    summaryString,
    async requestDeliveryInfo() {
      const { coefficient } = await this.$store.dispatch('order/getWeightCoefficient', {
        weight: totalProductsWeight(this.item.products),
      })

      this.weightCoefficient = coefficient

      const commonDeliveryParamsRequestFields = {
        quantity: totalProductsWeight(this.item.products),
        productsWarehouseCity: this.productsWarehouse.city.id,
        weightCoefficient: coefficient,
      }

      let response

      if (this.selectedDeliveryMethod === DELIVERY_TYPE.PICKUP) {
        response = await getPickupParams({
          ...commonDeliveryParamsRequestFields,
          selectedForPickupWarehouseCity: this.selectedWarehouseDetails.city.id,
        })
      } else if (this.selectedDeliveryMethod === DELIVERY_TYPE.DELIVERY) {
        response = await getDeliveryParams({
          ...commonDeliveryParamsRequestFields,
          selectedForDeliveryCity: this.selectedWarehouseDetails.city.id,
        })
      }

      return response
    },
    async getDeliveryInfo() {
      if (
        (this.selectedDeliveryMethod === DELIVERY_TYPE.PICKUP && this.selectedWarehouse) ||
        (this.selectedDeliveryMethod === DELIVERY_TYPE.DELIVERY && this.selectedCityId)
      ) {
        try {
          const { days, priceKg, minimalWeight } = await this.requestDeliveryInfo()

          const updatedDeliveryInfo = {
            days,
            min_weight_kg: minimalWeight,
            price_kg: priceKg,
          }

          if (Object.values(updatedDeliveryInfo).some(v => v === null)) {
            this.error = {
              ...this.error,
              deliveryUnavailable: this.ERROR_STRINGS.DELIVERY_UNAVAILABLE,
            }

            this.deliveryInfo = updatedDeliveryInfo
            return
          }

          this.deliveryInfo = updatedDeliveryInfo

          if (this.totalWeight < this.deliveryInfo.min_weight_kg) {
            this.error = {
              ...this.error,
              invalidWeight: this.ERROR_STRINGS.INVALID_WEIGHT,
            }
            return
          }

          this.error = {
            ...this.error,
            invalidWeight: null,
            deliveryUnavailable: null,
          }
        } catch (e) {
          this.error = {
            ...this.error,
            deliveryUnavailable: this.ERROR_STRINGS.DELIVERY_UNAVAILABLE,
          }
        }
      }
    },
    handleSelectCity(city) {
      this.selectedCityId = city.id
    },
    keepByArea(cityOrDistrictId) {
      for (let product of this.item.products) {
        if (product.warehouse_address.city.id !== cityOrDistrictId) {
          this.$store.dispatch('localCart/removeFromLocalCart', product)
        }
      }
    },
    citySearchRequest: debounce(function () {
      if (this.cityQuery.length) {
        this.$store.dispatch('misc/loadCities', {
          country: RUSSIA_COUNTRY_ID,
          name: this.cityQuery,
        })
      }
    }, 500),
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common-imports.scss';

.cart-item {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid $LGray6;

  &__seller {
    border-bottom: 1px solid $LGray6;
    padding-bottom: 12px;
  }

  &__product {
    border-bottom: 1px solid $LGray6;
  }

  &__footer {
    padding-top: 12px;
    font-weight: 500;
  }

  &__user-additional {
    padding-top: 8px;
    &::v-deep {
      .user-additional-info-box__about {
        padding-left: 0;
      }
    }
  }

  &__delivery {
    padding-bottom: 12px;
    border-bottom: 1px solid $LGray6;
  }

  &__delivery-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding-top: 12px;
  }

  &__delivery-option {
    padding: 6px;
    border: 1px solid $LGray6;
    border-radius: 8px;
    background-color: $White;

    &--active {
      background-color: $GreenM;
      color: $White;
      border-color: $GreenM;
    }
  }

  &__location-select {
    margin-top: 12px;
  }

  &__delivery-notice {
    padding: 8px;
    font-weight: 400;
    display: inline-flex;
    background-color: transparent;
    border-color: #d1d5db;
    border-radius: 6px;
    margin-bottom: 12px;
    font-size: 14px;

    > svg {
      width: 18px;
      height: 18px;
      margin-right: 4px;
      flex-shrink: 0;
    }
  }

  &__delivery-price {
    margin-top: 12px;
    font-size: 16px;

    > p {
      font-weight: 500;
    }
  }

  &__error {
    font-weight: 400;
    font-size: 14px;
    margin: 0;
    margin-top: 12px;
    padding: 6px 8px;
    border-radius: 6px;
    display: inline-block;
  }

  &__location-search {
    position: relative;
    margin-top: 12px;

    &::v-deep {
      .cart-item__location-input {
        border-radius: 8px;
      }

      .vbst-item {
        padding: 6px 8px !important;
      }
    }
  }

  &__delivery-ineligible {
    font-weight: 500;
    margin-top: 12px;
    padding: 8px;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid $LRed;
    display: grid;
  }

  &__keep-products-in {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  &__keep-in {
    margin-top: 8px;
    padding: 6px;
    border: 1px solid $LGray6;
    cursor: pointer;
    border-radius: 8px;
    line-height: 1.25;
    transition: box-shadow 200ms ease;
    text-align: left;

    &:hover {
      border-color: $GreenM;
    }
  }
}

.cart-product {
  display: grid;
  gap: 12px;
  padding: 12px 0;

  &__name {
    display: grid;
    grid-template-columns: 24px 1fr 16px;
    gap: 8px;
    align-items: center;
    font-weight: 500;
  }

  &__quantity-and-price {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 8px;
  }

  &__quantity {
    display: flex;
    align-items: center;
  }

  &__quantity-field {
    width: 125px;

    &::v-deep .input {
      min-height: 30px;
    }
  }

  &__measure {
    margin-left: 8px;
  }

  &__price {
    font-weight: 500;
  }

  &__remove {
    color: $PlainRed;
  }

  &__total {
    font-weight: 500;
  }
}
</style>
