<template>
  <header
    class="header"
    :class="{
      'header--no-auth': !loggedIn,
    }"
  >
    <div v-if="loggedIn" class="header__auth">
      <router-link class="header__logo" :to="{ name: 'home' }">
        <Logo :change-color-on-mobile="true" />
      </router-link>

      <nav class="header__main-nav">
        <MainNav :navigation="userNav.mainNavigation" />
      </nav>

      <nav class="header__additional-nav">
        <UserNav :navigation="userNav.additionalNavigation" />
      </nav>

      <div class="header__account">
        <router-link
          :is="isSupportAgent ? 'span' : 'router-link'"
          class="header__account-avatar"
          :to="{ name: 'profileIndex' }"
        >
          <img
            :src="
              currentUser.photo_small
                ? resolveAssetUrl(currentUser.photo_small)
                : require('@/assets/images/icons/nophoto.png')
            "
            :alt="currentUser.name"
          />
        </router-link>
      </div>

      <router-link v-if="isBuyer" class="header__cart" :to="{ name: 'cart' }">
        <div class="header__cart-icon">
          <i class="icon-cart"></i>
          <span v-if="hasCartItems" class="cart-quantity">{{ cartItemsProducts.length }}</span>
        </div>
      </router-link>

      <router-link v-else-if="isSeller" class="header__cart" :to="{ name: 'pendingOrders' }">
        <div class="header__cart-icon">
          <img src="@/assets/images/icons/inbox.svg" />
          <span v-if="hasCartItems" class="cart-quantity">{{ count }}</span>
        </div>
      </router-link>
    </div>

    <div v-else class="header__no-auth">
      <router-link class="header__logo" :to="{ name: 'home' }">
        <Logo :change-color-on-mobile="false" />
      </router-link>

      <nav class="header__links">
        <!-- <MainNav :navigation="userNav.mainNavigation" @show-modal="displayModal = $event" /> -->
      </nav>

      <div class="header__no-auth-actions">
        <a
          href="https://baza.store/voprosy-i-otvety/"
          class="header__help"
          title="FAQ"
          target="_blank"
          @click="sendMetrics('click_help')"
        >
          <CircleHelp :size="20" stroke="#1d9f3a" />
        </a>

        <LanguageSelect :small="true" class="header__lang" />

        <router-link :to="{ name: 'login' }">
          <span class="btn btn-new header__sign-link" @click="sendMetrics('click_login')">
            {{ $t('header.signIn') }}
          </span>
        </router-link>

        <router-link :to="{ name: 'registerBuyer' }">
          <span class="btn btn-new header__sign-link" @click="sendMetrics('click_register')">
            {{ $t('header.signUp') }}
          </span>
        </router-link>

        <button class="button-reset header__no-auth-cart" @click="showCartSidebar">
          <div class="header__cart-icon">
            <i class="icon-cart"></i>

            <span v-if="localCartItemsProducts.length" class="cart-quantity">
              {{ localCartItemsProducts.length }}
            </span>
          </div>
        </button>
      </div>
    </div>

    <AppModal v-if="displayModal === 'app-links-modal'" :dialog-max-width="400" @close="displayModal = null">
      <div class="app-links">
        <a href="https://apps.apple.com/ru/app/baza-store/id1605583568?l=ru" target="_blank" class="app-link">
          <img src="@/assets/images/icons/Apple-logo.png" width="32" height="32" />
          <span>App Store</span>
        </a>

        <a href="https://play.google.com/store/apps/details?id=com.bazastore&hl=ru" target="_blank" class="app-link">
          <img src="@/assets/images/icons/Google-play.png" width="32" height="32" />
          <span>Google Play</span>
        </a>
      </div>
    </AppModal>
  </header>
</template>

<script>
import Logo from './Logo'
import { mapState, mapGetters } from 'vuex'
import MainNav from './MainNav'
import UserNav from './UserNav'
import { API_URL, USER_NAVIGATION } from '@/constants.js'
import { resolveAssetUrl } from '@/helpers/url'
import LanguageSelect from './LanguageSelect.vue'
import inResponsiveSizes from '@/helpers/filters/inResponsiveSizes'
import { sendMetrics } from '@/utils/metrics'
import { CircleHelp } from 'lucide-vue'
import AppModal from '../modals/AppModal.vue'
import { eventBus } from '@/helpers'

export default {
  components: {
    Logo,
    MainNav,
    UserNav,
    LanguageSelect,
    CircleHelp,
    AppModal,
  },
  data: () => ({ API_URL, displayModal: null }),
  computed: {
    ...mapState('user', ['currentUser']),
    ...mapState('cart', ['cartItems', 'count']),
    ...mapGetters('cart', ['cartItemsProducts']),
    ...mapGetters('user', ['isSupportAgent', 'isSystemSeller', 'isSeller', 'isBuyer']),
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('localCart', ['localCartItemsProducts']),
    userNav() {
      if (!this.loggedIn) {
        return USER_NAVIGATION.noAuth
      }

      if (!this.isSupportAgent && this.isSystemSeller) {
        return USER_NAVIGATION.systemSeller
      }

      return USER_NAVIGATION[this.currentUser.user_type]
    },
    hasCartItems() {
      return this.cartItems?.length > 0
    },
  },
  methods: {
    resolveAssetUrl,
    inResponsiveSizes,
    sendMetrics,
    showCartSidebar() {
      eventBus.$emit('showCartSidebar', true)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.header {
  background-color: $White;

  @include media-breakpoint-up(md) {
    min-height: $size-header-height;
  }

  &__auth {
    display: grid;
    grid-template-columns: auto 1fr max-content max-content;
    grid-template-areas: 'logo additional-nav additional-nav additional-nav' 'main-nav main-nav user cart';
    max-width: 100%;

    @include media-breakpoint-down(sm) {
      grid-template-rows: 41px 50px;
      padding: 0 16px;

      &::before {
        content: '';
        display: block;
        grid-column: 1 / -1;
        grid-row: 1 / span 1;
        background-color: $GreenM;
        color: $White;
        margin-left: -16px;
        margin-right: -16px;
      }
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: auto 1fr max-content max-content;
      grid-template-areas: 'logo main-nav additional-nav cart';
      grid-gap: 30px;
    }

    .header__logo {
      padding-left: 16px;
      padding-right: 16px;

      @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
        max-width: 75px;
      }
    }
  }

  &__no-auth {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'logo links actions';
    column-gap: 16px;
    width: 100%;

    @include media-breakpoint-down(sm) {
      padding-right: 0;
      grid-template-columns: auto 1fr;
      grid-template-areas: 'logo actions' 'links links';
      padding-top: 8px;
    }

    @include media-breakpoint-down(xs) {
      column-gap: 8px;
    }

    .header__logo {
      display: flex;
      align-items: center;
      padding-right: 12px;

      @include media-breakpoint-down(sm) {
        padding-right: 0;
        padding-left: 12px;
      }

      @media (max-width: 374px) {
        .logo {
          max-width: 72px;
        }
      }
    }

    .header__help {
      @include media-breakpoint-down(xs) {
        width: 30px;
        height: 30px;
      }
    }

    .header__links {
      position: relative;
      grid-area: links;
      overflow-x: auto;
      background: linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0)),
        linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%) 100% 100%,
        radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)) 100% 100%;
      background-size: 40px 100%, 40px 100%, 7px 100%, 7px 100%;
      background-repeat: no-repeat;
      background-attachment: local, local, scroll, scroll;
      // min-width: 120px;
      display: flex;

      @include media-breakpoint-down(sm) {
        margin-top: 8px;
        padding-left: 12px;
        padding-right: 12px;
        border-top: 1px solid $LGray3;

        &::after {
          // Margin imitation
          content: '';
          width: 16px;
        }
      }
    }
  }

  &__no-auth-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-area: actions;
    margin-left: auto;

    @include media-breakpoint-down(sm) {
      padding-right: 12px;
    }

    > * + * {
      margin-left: 16px;

      @include media-breakpoint-down(lg) {
        margin-left: 12px;
      }

      @include media-breakpoint-down(xs) {
        margin-left: 8px;
      }
    }
  }

  &--no-auth {
    display: flex;
    background-color: $White;
    padding: 0 16px;

    @include media-breakpoint-down(sm) {
      padding: 0;
    }

    &::before {
      display: none;
    }
  }

  // Elements
  .header__logo {
    grid-area: logo;
    color: $GreenM;
    align-self: center;

    @include media-breakpoint-down(sm) {
      color: $White;
    }
  }

  .header__main-nav {
    grid-area: main-nav;

    @include media-breakpoint-down(md) {
      overflow-x: auto;
      background: linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0)),
        linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%) 100% 100%,
        radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)) 100% 100%;
      background-size: 40px 100%, 40px 100%, 7px 100%, 7px 100%;
      background-repeat: no-repeat;
      background-attachment: local, local, scroll, scroll;
      margin-left: -20px;
      padding-left: 20px;
      min-width: 120px;
    }
  }

  .header__additional-nav {
    grid-area: additional-nav;
    justify-self: end;
    align-self: center;
  }

  .header__cart {
    grid-area: cart;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $GreenM;
    height: 50px;
    width: auto;
    font-size: 26px;
    transition: 0.3s ease opacity 0s;

    &:hover {
      text-decoration: none;
      opacity: 0.6;
    }

    @include media-breakpoint-up(md) {
      height: $size-header-height;
      width: $size-header-height;
      border-left: 1px solid $LGray3;
    }

    .icon-cart {
      position: relative;
      font-size: 26px;
    }

    // Elements
    &::after {
      transform: translate(-50%, -80%);
    }
  }

  .header__no-auth-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: $GreenM;
    height: auto;

    @include media-breakpoint-up(sm) {
      padding-left: 8px;
      padding-right: 8px;
    }

    @include media-breakpoint-up(md) {
      height: $size-header-height;
      border-left: 1px solid $LGray3;
      padding-left: 20px;
    }
  }

  .header__cart-icon {
    position: relative;

    .cart-quantity {
      position: absolute;
      top: -4px;
      left: calc(100% - 10px);
      display: inline-block;
      border-radius: 8px;
      padding: 0 4px;
      background-color: $GreenM;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.2;
      color: $White;
      font-family: $fontMain, sans-serif;
      border: 1px solid $White;
    }
  }

  .header__account {
    grid-area: user;
    display: none;
    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
    }
  }
  .header__account-avatar {
    display: inline-flex;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__sign-link {
    padding: 12px;
    border-radius: 12px;
    background-color: $GreenM;
    color: $White;

    @include media-breakpoint-down(md) {
      border-radius: 10px;
      padding: 10px;
      font-size: 14px;
    }

    @include media-breakpoint-down(xs) {
      border-radius: 8px;
      padding: 8px;
      font-size: 12px;
      font-weight: 600;
    }

    &:hover {
      background-color: $GreenMDarker;
    }
  }

  &__help {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: $White;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 8%);
  }
}

.app-links {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  gap: 16px;

  .app-link {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid $LGray3;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: $Dark100;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: $Dark100;
      border-color: $LGray6;
    }

    > img {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
  }
}
</style>
