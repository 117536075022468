<template>
  <span class="icon-flag">
    <img v-if="code" :title="title || alt" class="icon-flag__image" :src="src" :alt="alt" />
  </span>
</template>

<script>
export default {
  name: 'FlagIcon',
  props: {
    code: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
  computed: {
    src() {
      if (this.code) {
        return require(`@/assets/images/icons/flag/${this.code.toLowerCase()}.svg`)
      }
      return ''
    },
    alt() {
      return this.code?.toLowerCase() || ''
    },
  },
}
</script>

<style scoped>
.icon-flag {
  display: inline-block;
  width: 20px;
  height: 12px;
  max-width: 20px;
  max-height: 12px;
  border-radius: 1px;
  box-shadow: 0 0 1px #c4c4c4;
}

.icon-flag__image {
  display: block;
  object-fit: cover;
  width: inherit;
  height: inherit;
  border-radius: inherit;
}
</style>
