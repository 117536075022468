<template>
  <SidebarCheckboxList
    v-if="filteredCountries.length"
    :title="$t('Country')"
    :search-value="searchValue"
    :items="filteredCountries"
    :items-selected="countriesSelected"
    @search-input="searchValue = $event"
    @select="select"
  >
    <template #label="{ item }">
      <span>{{ item.name }}</span>
    </template>
  </SidebarCheckboxList>
</template>

<script>
import { mapState } from 'vuex'
import SidebarCheckboxList from '@/components/filters/sidebar/SidebarCheckboxList.vue'

export default {
  name: 'CountryFilter',
  components: {
    SidebarCheckboxList,
  },
  data: () => {
    return {
      searchValue: '',
    }
  },
  computed: {
    ...mapState('misc', ['countries']),
    ...mapState('marketCatalog', ['filterMetadata']),
    ...mapState('filtration', ['countriesSelected']),
    filteredCountries() {
      const productCountries = this.filterMetadata?.countries || []
      const countriesSet = new Set(productCountries.map(c => c.id))
      return this.countries.filter(c => countriesSet.has(c.id))
    },
  },
  methods: {
    select(country) {
      if (this.countriesSelected.some(c => c.id === country.id)) {
        this.$store.dispatch(
          'filtration/setCountriesSelected',
          this.countriesSelected.filter(c => c.id !== country.id),
        )
      } else {
        this.$store.dispatch('filtration/setCountriesSelected', [...this.countriesSelected, country])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common-imports.scss';

.country-filter {
}
</style>
