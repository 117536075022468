export default {
  order: {
    new: 'Ожидает подтверждения',
    confirm: 'Подтвержден',
    seller_update: 'Обновлен продавцом',
    buyer_confirm_update: 'Подтверждено покупателем',
    waiting_operator_confirm: 'Ожидает подтверждения оператора',
    operator_confirm: 'Подтвержден оператором',
    on_way: 'В пути',
    operator_update: 'Изменен оператором',
    buyer_confirm_operator_update: 'Ожидает подтверждения покупателя',
    seller_confirm_operator_update: 'Ожидает подтверждения продавца',
    received_dc: 'Получено в ПВЗ',
    sent_from_dc: 'Доставка',
    pickup_ready: 'Готов к выдаче',
    collected: 'Собран',
    waiting_truck: 'Ожидает транспорт',
    waiting_filled_truck: 'Ожидает отгрузки',
    filling: 'Загружается',
    filled: 'Доставка',
    deliver: 'Получено',
    report: 'Есть возврат',
    adjustment_by_admin: 'Рассмотрен администратором',
    put_on_realization: 'Поставлен на реализацию',
    hold: 'На удержании',
    rate_buyer: 'Оценка покупателя',
    rate_seller: 'Оценка поставщика',
    complete: 'Завершен',
    cancel: 'Отменен',
    operator_cancel: 'Аннулирован',
    request_cancelation: 'Запрос на аннулирование',
  },
  orderReportedItems: {
    request_created: 'Запрос создан',
    sent_to_seller: 'Отправлено продавцу',
    seller_received_return: 'Получено продавцом',
  },
  payment: {
    expected: 'Ожидается',
    partial: 'Частичная оплата',
    expired: 'Просрочено',
    failed: 'Не удалось',
    paid: 'Оплачено',
    dispute: 'Спор',
    canceled: 'Отменен',
  },
  application: {
    new: 'Новый',
    open: 'Открытый',
    closed: 'Закрытый',
  },
  orderTimeline: {
    isNew: 'Заказ',
    isConfirmation: 'Согласование',
    isOperatorConfirm: 'Подтверждение Оператора',
    isGoodsInTransit: 'В пути',
    isReceivedDC: 'Получено в ПВЗ',
    isSentFromDC: 'Доставка',
    isReadyForPickup: 'Готов к выдаче',
    isCollection: 'Сборка',
    isDelivery: 'Доставка',
    isObtain: 'Приёмка',
    isReport: 'Завершено',
  },
  supplyRequest: {
    created: 'Новый',
    closed: 'Закрыто',
    rejected: 'Отменен',
    pending: 'На рассмотрении',
    received: 'Получено',
    approved: 'Одобрено',
  },
  document: {
    checking: 'Проверка',
    accepted: 'Принят',
    rejected: 'Отклонён',
  },
  cooperationRequest: {
    PENDING: 'В ожидании',
    REJECTED: 'Отклонено',
    APPROVED: 'Принято',
    ACCEPTED: 'Принято',
  },
}
