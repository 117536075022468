<template>
  <div class="app-modal-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppModalContainer',
}
</script>

<style lang="scss" scoped>
.app-modal-container {
  background: #ffffff;
  box-shadow: 0px 10px 32px rgba(0, 0, 0, 0.06);
  border-radius: 32px;
  padding: 32px;

  @media (max-width: 576px) {
    padding: 16px;
    border-radius: 24px;
  }
}
</style>
