export default {
  setScreenSize({ commit }, size) {
    commit('changeScreenSize', size)
  },
  setSidebarOpened({ commit }, sidebarOpened) {
    commit('changeSidebarOpened', sidebarOpened)
  },
  setGoodsFiltersOpened({ commit }, opened) {
    commit('changeGoodsFiltersOpened', opened)
  },
  setGoodsFiltersActiveTab({ commit }, activeTab) {
    commit('changeGoodsFiltersActiveTab', activeTab)
  },
  setFooterHeight({ commit }, height) {
    commit('changeFooterHeight', height)
  },
}
