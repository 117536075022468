import buyersRoutes from './buyer'
import providerRoutes from './provider'
import store from '@/store'
import { checkHasCompletedUserPreferencesSurvey } from '@/funcs/userPreferencesSurvey'
import { eventBus } from '@/helpers'
import i18n from '@/i18n'

export default [
  // Uncaught (in promise) Error: Redirected when going from "/login?redirect=%2Fmarket" to "/market" via a navigation guard.
  {
    path: '/',
    name: 'home',
    beforeEnter(to, from, next) {
      return next({
        name: store.getters['user/isSeller'] || store.getters['user/isExporter'] ? 'products' : 'market',
      })
    },
  },
  // Profile
  {
    path: '/login/',
    name: 'login',
    pathToRegexpOptions: { strict: true },
    component: () => import('../../views/Login.vue'),
  },
  {
    path: '/login',
    redirect: '/login/',
  },
  {
    path: '/register',
    name: 'register',
    componentName: 'BlankChildrenRoute',
    redirect: { name: 'login' },
    children: [
      {
        path: '/register/seller-exporter/',
        name: 'registerSeller',
        pathToRegexpOptions: { strict: true },
        component: () => import('../../views/register/RegisterSeller.vue'),
      },
      { path: '/register/seller-exporter', redirect: '/register/seller-exporter/' },
      {
        path: '/register/buyer/',
        name: 'registerBuyer',
        pathToRegexpOptions: { strict: true },
        component: () => import('../../views/register/RegisterBuyer.vue'),
      },
      { path: '/register/buyer', redirect: '/register/buyer/' },
    ],
  },
  // Указание email для восстановления пароля
  {
    path: '/password-reset/',
    name: 'resetPassword',
    pathToRegexpOptions: { strict: true },
    component: () => import('../../views/ResetPassword.vue'),
  },
  { path: '/password-reset', redirect: '/password-reset/' },
  // Страница восстановления пароля
  {
    path: '/set-password/',
    name: 'updatePassword',
    pathToRegexpOptions: { strict: true },
    component: () => import('../../views/UpdatePassword.vue'),
  },
  { path: '/set-password', redirect: '/set-password/' },
  // Profile
  {
    path: '/profile',
    componentName: 'BlankChildrenRoute',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/profile',
        component: () => import('../../views/Profile.vue'),
        name: 'profileIndex',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'edit',
        component: () => import('../../views/ProfileEdit.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '/profile/edit',
            component: () => import('../../views/profileEdit/Main.vue'),
            name: 'profileEdit',
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'organization',
            component: () => import('../../views/profileEdit/Organization.vue'),
            name: 'profileEditOrganization',
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'warehouse',
            component: () => import('../../views/profileEdit/Warehouse.vue'),
            name: 'profileEditWarehouse',
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'password',
            component: () => import('../../views/profileEdit/Password.vue'),
            name: 'profileEditPassword',
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      // {
      //   path: 'interests',
      //   component: () => import('../../views/profile/MyInterests.vue'),
      //   name: 'interests',
      //   meta: { requiresAuth: true },
      // },
      // {
      //   path: 'my-business',
      //   component: () => import('../../views/profile/MyBusiness.vue'),
      //   name: 'myBusiness',
      //   meta: { requiresAuth: true },
      // },
    ],
  },

  // Deliveries
  {
    path: '/deliveries',
    component: () => import('../../views/BlankChildrenRoute.js'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/deliveries',
        component: () => import('../../views/Deliveries.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '/deliveries',
            name: 'deliveries',
            component: () => import('../../views/deliveries/Orders.vue'),
            meta: {
              sidebarComponents: ['AccordionFilter'],
              filterItemsToShow: ['orderPartners', 'orderStatuses'],
              requiresAuth: true,
            },
          },
          {
            path: 'mutual-settlement',
            name: 'deliveriesCalcs',
            component: () => import('../../views/deliveries/Payouts.vue'),
            meta: {
              requiresAuth: true,
              sidebarComponents: ['AccordionFilter'],
              filterItemsToShow: ['financePartners', 'financeStatuses'],
            },
          },
          {
            path: 'supply-requests',
            name: 'supplies',
            component: () => import('../../views/deliveries/DistributionCenter.vue'),
            beforeEnter(to, from, next) {
              store.getters['user/isBuyer'] ? next({ name: 'deliveries' }) : next()
            },
            redirect: { name: 'incomingSupplyRequests' },
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: 'incoming',
                name: 'incomingSupplyRequests',
                component: () => import('../../views/supplies/IncomingSupplies.vue'),
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: 'outgoing',
                name: 'outgoingSupplyRequests',
                component: () => import('../../views/supplies/OutgoingSupplies.vue'),
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: 'cooperation',
                name: 'cooperation',
                component: () => import('../../views/supplies/Cooperations.vue'),
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: ':deliveryId',
        name: 'delivery',
        component: () => import('../../views/Delivery.vue'),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'supply-requests',
        name: 'supplyRequests',
        beforeEnter(to, from, next) {
          store.getters['user/isBuyer'] ? next({ name: 'deliveries' }) : next()
        },
        component: () => import('../../views/BlankChildrenRoute.js'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'create',
            component: () => import('../../views/AddSupplyRequest.vue'),
            name: 'addSupplyRequest',
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ':supplyRequestId',
            component: () => import('../../views/SupplyRequest.vue'),
            name: 'supplyRequest',
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/finance',
    component: () => import('../../views/BlankChildrenRoute.js'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/finance',
        name: 'finance',
        component: () => import('../../views/Finance.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  // Cart
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../../views/Cart.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // Chat
  {
    path: '/chat',
    component: () => import('../../views/Chat.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true,
    children: [
      {
        path: '/chat',
        name: 'chat',
        component: () => import('../../views/chat/EmptySelected.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':userId',
        name: 'chatUser',
        component: () => import('../../views/chat/UserChat.vue'),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  // Checkout
  {
    path: '/checkout/:cartItemIds',
    name: 'checkout',
    component: () => import('../../views/Checkout.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/demand-checkout/:cartItemId',
    name: 'demandCheckout',
    component: () => import('../../views/DemandCheckout.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  // Standarts
  {
    path: '/standarts',
    name: 'standarts',
    component: () => import('../../views/Standarts.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  // Specific routes
  ...buyersRoutes,
  ...providerRoutes,
  // Dont add after this anyothing
  {
    path: '/success/:applicationId',
    name: 'success',
    component: () => import('../../views/SuccessfulOrdering.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/support-chat/',
    name: 'supportChat',
    props: true,
    pathToRegexpOptions: { strict: true },
    meta: {
      requiresAuth: false,
    },
    component: () => import('../../views/SupportChat.vue'),
  },
  {
    path: '/support-chat',
    redirect: '/support-chat/',
  },
  {
    path: '/setup-survey',
    component: () => import('../../views/Survey.vue'),
    name: 'setupSurvey',
    async beforeEnter(to, from, next) {
      if (store.getters['auth/loggedIn']) {
        const completed = await checkHasCompletedUserPreferencesSurvey()

        if (completed) {
          setTimeout(() => {
            eventBus.$emit('error', i18n.t('You have already taken the survey'))
          }, 500)
          return next({ name: 'home' })
        } else {
          return next()
        }
      } else {
        return next()
      }
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import('../../views/NotFound.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
