<template>
  <SidebarCheckboxList
    v-if="filteredSorts.length"
    :title="$t('Grade')"
    :search-value="searchValue"
    :items="filteredSorts"
    :items-selected="sortsSelected"
    @search-input="searchValue = $event"
    @select="select"
  >
    <template #label="{ item }">
      <span>{{ item.name }}</span>
    </template>
  </SidebarCheckboxList>
</template>

<script>
import { mapState } from 'vuex'
import SidebarCheckboxList from '@/components/filters/sidebar/SidebarCheckboxList.vue'

export default {
  name: 'GradeFilter',
  components: {
    SidebarCheckboxList,
  },
  data: () => {
    return {
      searchValue: '',
    }
  },
  computed: {
    ...mapState('product', ['products', 'sorts']),
    ...mapState('filtration', ['sortsSelected']),
    filteredSorts() {
      if (this.currentProduct) {
        return this.currentProduct.sort
      }

      return this.sorts
    },
    currentProduct() {
      return this.products.find(p => p.routeName === this.$route.params.productName)
    },
  },
  watch: {
    currentProduct() {
      this.searchValue = ''
    },
  },
  methods: {
    select(sort) {
      if (this.sortsSelected.some(s => s.id === sort.id)) {
        this.$store.dispatch(
          'filtration/setProductSortsSelected',
          this.sortsSelected.filter(s => s.id !== sort.id),
        )
      } else {
        this.$store.dispatch('filtration/setProductSortsSelected', [...this.sortsSelected, sort])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common-imports.scss';

.grade-filter {
}
</style>
