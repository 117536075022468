export default {
  modals: {
    submitAction: {
      deleteProduct: 'Вы уверены, что хотите удалить продукт?',
      actionCantBeRejected: 'Вы не сможете отменить действие.',
      registerTitle: 'Регистрация успешно завершена!',
      deleteWarehouseBuyer: 'Удалить адрес доставки?',
      deleteWarehouse: 'Вы уверены, что хотите удалить склад?',
      unpublishProduct: 'Вы уверены, что хотите снять продукт с публикации?',
      publishProduct: 'Вы уверены, что хотите опубликовать продукт?',
      deleteApplication: 'Вы уверены, что хотите удалить заявку?',
    },
    confirmationButton: {
      request_cancelation: 'Аннулировать',
      cancel: 'Отменить',
      seller_update: 'Согласовать',
      buyer_confirm_update: 'Согласен',
      seller_confirm_operator_update: 'Согласен',
      waiting_operator_confirm: 'Согласен',
      sent_from_dc: 'Согласен',
      on_way: 'Отправить',
      submit: 'Подтвердить',
      confirm: 'Подтвердить',
      collected: 'Уведомить о сборке',
      filled: 'Отправить',
      waiting_truck: 'Ожидаю транспорт',
      waiting_filled_truck: 'Ожидаю погрузку',
      deliver: 'Получить',
      send: 'Отправить',
      put_on_realization: 'Подтвердить',
      return_registration: 'Подтвердить',
      seller_correct: 'Исправлено',
      hold: 'Удержать',
      complete: 'Завершить',
      estimate: 'Оценить',
      add: 'Добавить',
      rate_buyer: 'Оценить',
      delete: 'Удалить',
      pickup_ready: 'Подтвердить',
    },
    confirmationTitle: {
      request_cancelation: 'Отправить запрос на отмену заказа?',
      cancel: 'Отменить заказ?',
      seller_update: 'Отправить изменения на согласование?',
      buyer_confirm_update: 'Вы согласны с изменениями?',
      seller_confirm_operator_update: 'Вы согласны с изменениями?',
      sent_from_dc: 'Вы согласны с изменениями?',
      waiting_operator_confirm: 'Подтвердить заказ',
      on_way: 'Отправить товары в РЦ?',
      submit: 'Подтвердить заказ',
      confirm: 'Подтвердить заказ',
      collected: 'Подтверждаете сборку заказа?',
      filled: 'Подтверждаете полную отгрузку заказа?',
      waiting_truck: 'Подтвердить действие',
      waiting_filled_truck: 'Подтвердить действие',
      deliver: 'Заказ доставлен?',
      send: 'Подтвердить действие',
      seller_correct: 'Отправить изменения на согласование?',
      hold: 'Вы несогласны с корректировкой?',
      put_on_realization: 'Поставить на реализацию',
      return_registration: 'Оформить возврат',
      complete: 'Завершить заказ',
      estimate: 'Оставить отзыв сейчас?',
      pickup_ready: 'Заказ готов к выдаче?',
    },
    confirmationProductButton: {
      delete: 'Удалить продукт',
      renewDate: 'Обновить дату',
      unpublish: 'Снять с публикации',
      publish: 'Опубликовать',
    },
    confirmationCommonButton: {
      deleteWarehouseBuyer: 'Удалить адрес доставки',
      deleteWarehouse: 'Удалить склад',
    },
  },
}
